import React from 'react';
import {
    Card, Form, Input, Button, Tooltip, Upload, message,
} from 'antd';
import {
    InfoCircleOutlined,
    PaperClipOutlined,
    PictureOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import CustomAvatar from '../../components/Avatar';
import Messages from './messages';
import { getLang } from '../../config/helpers';
import { getUserInitials, getFullName } from '../../helpers/functions';
import { setPatientsAsync } from '../../redux/actions/auth';
import { addImages } from '../../api/images';
import TEXTS from './_texts/index.texts';
import './styles.css';

const lang = getLang();

const TelecTabs = ({
    activeTelec,
    onFinishMessages,
    messagesForm,
    activeConnection,
    messages,
    showPatientInfo,
    setPatientsAsync,
}) => {
    const uploadImage = async (file) => {
        try {
            const uploadRes = await addImages(activeTelec.patient._id, 'photos', file);
            const patientImages = uploadRes.data.photos;
            const mostRecentImage = patientImages[patientImages.length - 1];

            message.success(TEXTS.uploadSuccessMSG[lang]);

            await setPatientsAsync();

            onFinishMessages({ messageToSend: `img##${mostRecentImage}` });
        } catch (err) {
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    return (
        <Card
            style={{ height: '80vh' }}
            className="telec-messages-card"
            title={(
                <div className="flex" style={{ alignItems: 'center' }}>
                    <CustomAvatar
                        size={30}
                        style={{ marginRight: 10 }}
                        initials={getUserInitials(activeTelec.patient)}
                    />
                    <b>{getFullName(activeTelec.patient)}</b>

                    <Tooltip title="Cliquer pour voir les informations patient">
                        <InfoCircleOutlined
                            className="m-left-small"
                            style={{ cursor: 'pointer' }}
                            onClick={showPatientInfo}
                        />
                    </Tooltip>
                </div>
            )}
            actions={[
                <div className="flex-column" style={{ margin: '0 1em' }}>
                    <Form onFinish={onFinishMessages} form={messagesForm}>
                        <div className="message-input-wrapper">
                            <Form.Item className="input-formitem" name="messageToSend">
                                <Input
                                    autoFocus
                                    size="large"
                                    placeholder={TEXTS.messagePlacholder[lang]}
                                />
                            </Form.Item>

                            <div className="input-actions">
                                <Tooltip title="Charger un document">
                                    <Button
                                        type="text"
                                        size="large"
                                        icon={<PaperClipOutlined />}
                                    />
                                </Tooltip>
                                <Tooltip title="Charger une image">
                                    <Upload
                                        showUploadList={false}
                                        customRequest={({ file }) => {
                                            uploadImage(file);
                                        }}
                                        listType="text"
                                    >
                                        <Button
                                            type="text"
                                            size="large"
                                            icon={<PictureOutlined />}
                                        />
                                    </Upload>
                                </Tooltip>
                            </div>
                        </div>
                        <Form.Item>
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                disabled={!activeConnection}
                            >
                                {TEXTS.sendMessage[lang]}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>,
            ]}
        >
            <div style={{ height: '100%', overflow: 'auto' }} id="messages-container">
                <Messages messages={messages} />
            </div>
        </Card>
    );
};

TelecTabs.propTypes = {
    activeTelec: PropTypes.object.isRequired,
    onFinishMessages: PropTypes.func.isRequired,
    messagesForm: PropTypes.object.isRequired,
    activeConnection: PropTypes.object,
    messages: PropTypes.array.isRequired,
    showPatientInfo: PropTypes.func.isRequired,
    setPatientsAsync: PropTypes.func.isRequired,
};

TelecTabs.defaultProps = {
    activeConnection: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    activeTelec: state.consultations.activeTelec,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setPatientsAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(TelecTabs);
