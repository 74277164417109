export default {
    tooltip: {
        fr: 'Revenu total généré',
        en: 'Total generated revenu',
    },
    country: {
        fr: 'Pays',
        en: 'Country',
    },
};
