/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Layout, Typography, Menu, Popover,
} from 'antd';
import { connect, useDispatch } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { mapRouteToElement } from '../Sidebar/config';
import './styles.css';
import { disconnectAsync } from '../../redux/actions/auth';
import { loadProfileImage } from '../../api/images';
import { getFullName } from '../../helpers/functions';
import CustomAvatar from '../Avatar';


const { Header } = Layout;
const { Title } = Typography;

const AppHeader = ({ currentRoute, user }) => {
    const [openPop, setOpenPop] = useState(false);
    const routeInfo = mapRouteToElement[currentRoute];
    if (!routeInfo) return <div />;
    const dispatch = useDispatch();

    const PopoverContent = (
        <Menu>
            <Menu.Item onClick={() => dispatch(disconnectAsync())} icon={<LogoutOutlined />} key="0">
                Se déconnecter
            </Menu.Item>
        </Menu>
    );


    return (
        <Header className="header header-top justify-c-sb">

            <div className="m-left-medium flex" style={{ height: 45 }}>
                <div className="m-right-medium">
                    {routeInfo.icon({ fontSize: 40 })}

                </div>
                <Title level={3} style={{ lineHeight: 2 }}>
                    {routeInfo.title}
                </Title>

            </div>
            <Popover
                content={PopoverContent}
                trigger="hover"
                visible={openPop}
                onVisibleChange={setOpenPop}
            >
                <div
                    className="flex"
                    style={{ cursor: 'pointer', alignItems: 'center' }}
                >
                    <span className="m-right-medium">{getFullName(user)}</span>
                    <CustomAvatar
                        src={loadProfileImage(user._id, 'thumbnail')}
                        size={45}

                    />
                </div>
            </Popover>
        </Header>
    );
};

AppHeader.propTypes = {
    currentRoute: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
};

AppHeader.defaultProps = {
};

const stateToProps = state => ({
    user: state.auth.user,
});


export default connect(stateToProps)(AppHeader);
