export default {
    emptyImages: {
        fr: "Ce patient n'a téléchargé aucun document",
        en: "This patient dosen't have any uploaded document",
    },
    header: {
        fr: 'Vos patients',
        en: 'Your patients',
    },
    headerToolip: {
        fr: 'Liste des patients vous autorisant a consulter leurs documents',
        en: 'Patients that allowed you to consult their documents',
    },
    uploadSuccessMSG: {
        fr: 'Document ajouté avec succès.',
        en: 'Document uploaded successfully.',
    },
    uploadErrorMSG: {
        fr: "Une erreur s'est produite.",
        en: 'An error has occured, please retry.',
    },
};
