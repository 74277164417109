import {
    Input, Form, InputNumber, Button, message,
} from 'antd';
import React, { useEffect } from 'react';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/pricingForm.texts';
import './styles.css';
import { connect } from 'react-redux';
import { updateDoctorPricingAsync } from '../../redux/actions/auth';
import { bindActionCreators } from 'redux';

const lang = getLang();

const PricingForm = ({ updateDoctorPricingAsync, auth }) => {
    const [form] = Form.useForm();
    const { getFieldProps, getFieldError } = form;

    const onFinish = (values) => {
        const { pricing, pricingNight, pricingWeekEnd } = values;
        updateDoctorPricingAsync({ pricing, pricingNight, pricingWeekEnd });
        message.success(TEXTS.addedSuccessfully[lang]);
        form.resetFields();
    };

    useEffect(() => form.resetFields(), [auth]);

    return (
        <Form
            form={form}
            initialValues={{
                pricing: auth.pricing,
                pricingNight: auth.pricingNight,
                pricingWeekEnd: auth.pricingWeekEnd,
            }}
            onFinish={onFinish}
            id="pricing-form"
            layout="inline"
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            <div className="pricing-inputs-container">
                <Form.Item
                    label="Prix du jour"
                    name="pricing"
                    rules={[
                        {
                            required: true,
                            message: TEXTS.inputErrorRequired[lang],
                        },
                    ]}
                >
                    <InputNumber placeholder={TEXTS.pricing[lang]} />
                </Form.Item>

                <Form.Item
                    name="pricingNight"
                    label="Prix du nuit"
                    rules={[
                        {
                            required: true,
                            message: TEXTS.inputErrorRequired[lang],
                        },
                    ]}
                >
                    <InputNumber placeholder={TEXTS.pricingNight[lang]} />
                </Form.Item>
                <Form.Item
                    name="pricingWeekEnd"
                    label="Prix Samedi/Dimanche"
                    rules={[
                        {
                            required: true,
                            message: TEXTS.inputErrorRequired[lang],
                        },
                    ]}
                >
                    <InputNumber placeholder={TEXTS.pricingWeekEnd[lang]} />
                </Form.Item>
            </div>
            <Form.Item className="item add">
                <Button htmlType="submit" type="primary">
                    {TEXTS.addButton[lang]}
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToPros = state => ({
    auth: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateDoctorPricingAsync,
    },
    dispatch,
);

export default connect(mapStateToPros, dispatchToProps)(PricingForm);
