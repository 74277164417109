/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { checkAuthAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/upload.texts';
import { addImages } from '../../api/images';
import { addDocuments } from '../../api/documents';

const lang = getLang();

const UploadFile = ({
    user, checkAuthAsync, fileType,
}) => {
    const uploadImage = async (file) => {
        try {
            await addImages(user._id, 'photos', file);
            checkAuthAsync();
            message.success(TEXTS.uploadSuccessMSG[lang]);
        } catch (err) {
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    const uploadDocument = async (file) => {
        try {
            await addDocuments(user._id, 'documents', file);
            checkAuthAsync();
            message.success(TEXTS.uploadSuccessMSG[lang]);
        } catch (err) {
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    return (
        <div className="flex" style={{ justifyContent: 'space-around' }}>
            <Upload
                showUploadList={false}
                customRequest={({ file }) => {
                    setTimeout(() => {
                        if (fileType === 'image') { uploadImage(file); } else uploadDocument(file);
                    }, 0);
                }}
                listType="text"
            >
                <Button block type="primary" size="large" style={{ marginTop: 10 }}>
                    <UploadOutlined style={{ marginRight: 5 }} />
                    {TEXTS.uploadButton[lang]}
                </Button>
            </Upload>
        </div>
    );
};

UploadFile.propTypes = {
    user: PropTypes.object.isRequired,
    checkAuthAsync: PropTypes.func.isRequired,
    fileType: PropTypes.string,
};

UploadFile.defaultProps = {
    fileType: 'image',
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        checkAuthAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(UploadFile);
