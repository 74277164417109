export default {
    breadCrumb1: {
        fr: 'Téléconsultations',
        en: 'Teleconsultations',
    },
    breadCrumb2: {
        fr: 'Programmées',
        en: 'Upcoming',
    },
    count: {
        fr: 'Téléconsultations programmées',
        en: 'Upcoming teleconsultations',
    },
    emptyList: {
        fr: 'Aucune téléconsultation programmée.',
        en: 'No upcoming teleconsultations.',
    },
};
