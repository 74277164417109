import axios from 'axios';
import { API_DOCUMENTS, API_REQ_HEADER } from '../config';

const addDocuments = async (userId, category, documents) => {
    const data = new FormData();
    data.append('files', documents);
    const res = await axios.post(
        `${API_DOCUMENTS}/upload/${userId}/${category}`,
        data,
        API_REQ_HEADER,
    );
    return res.data;
};

const addListDocuments = async (userId, category, values) => {
    const { documents, categorie } = values;
    const data = new FormData();
    if (documents.length <= 1) {
        data.append('files', documents[0].originFileObj);
    } else {
        documents.forEach((doc) => {
            data.append('files', doc.originFileObj);
        });
    }
    data.append('categorie', categorie);
    data.append('doctor', true);
    const res = await axios.post(
        `${API_DOCUMENTS}/upload/${userId}/${category}`,
        data,
        API_REQ_HEADER,
    );
    return res.data;
};

const loadDocument = (userId, category, filename) => (
    `${API_DOCUMENTS}/load/${userId}/${category}/${filename}?${Date.now()}`
);


export {
    addDocuments, loadDocument, addListDocuments,
};
