import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Breadcrumb, Typography, List,
} from 'antd';
import {
    VideoCameraOutlined,
} from '@ant-design/icons';
import SectionHeader from '../../../components/SectionHeader';
import { setIncomingListAsync } from '../../../redux/actions/consultations';
import TelecCard from '../../../components/TelecCard';
import { getLang } from '../../../config/helpers';
import TEXTS from './_texts/index.texts';


const lang = getLang();
const { Title } = Typography;


const IncomingTelecs = ({ incomingList, setIncomingListAsync, user }) => {
    useEffect(() => {
        setIncomingListAsync();
    }, [user]);

    return (
        <>
            <SectionHeader>
                <Breadcrumb style={{ marginBottom: '1.5rem' }}>
                    <Breadcrumb.Item>
                        <VideoCameraOutlined style={{ marginRight: 5 }} />
                        {TEXTS.breadCrumb1[lang]}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {TEXTS.breadCrumb2[lang]}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="flex-column">
                    <Title level={4}>
                        {`${TEXTS.count[lang]} : ${incomingList.length}`}
                    </Title>
                </div>
            </SectionHeader>
            <div className="section-content-container">
                <List
                    locale={{ emptyText: TEXTS.emptyList[lang] }}
                    grid={{ gutter: 16, column: 2 }}
                    dataSource={incomingList}
                    renderItem={item => (
                        <>
                            <List.Item style={{ width: '100%' }}>
                                <TelecCard telec={item} />
                            </List.Item>

                        </>
                    )}
                />
            </div>
        </>
    );
};


IncomingTelecs.propTypes = {
    incomingList: PropTypes.array.isRequired,
    setIncomingListAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};


const stateToProps = state => ({
    incomingList: state.consultations.incomingList,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setIncomingListAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(IncomingTelecs);
