/* eslint-disable no-underscore-dangle */
import { DateTime } from 'luxon';
import { getLang } from '../../../../config/helpers';

const lang = getLang();


export const formatDateForTelec = date => DateTime.fromMillis(Number(date))
    .setLocale(lang)
    .toLocaleString(DateTime.DATETIME_FULL);

export default {
    bookedTitle: {
        fr: 'Téléconsultation réservée',
        en: 'Teleconsultation time slot booked',
    },
    bookedDescripton: {
        fr: info => `Le patient ${
            info[0]
        } a reservé votre créneau de téléconsultation du ${formatDateForTelec(
            info[1],
        )}`,
        en: info => `Your time slot for the ${formatDateForTelec(
            info[1],
        )} has been booked by the patient ${info[0]}`,
    },
    cancelledTitle: {
        fr: 'Téléconsultation annulée',
        en: 'Cancelled teleconsultation',
    },
    cancelledDescripton: {
        fr: info => `Le patient ${
            info[0]
        } a annulé la téléconsultation prévue pour le ${formatDateForTelec(
            info[1],
        )}`,
        en: info => `Your patient ${
            info[0]
        } have cancelled the teleconsultation planned for the ${formatDateForTelec(
            info[1],
        )}`,
    },
};
