import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {
    Button, message, Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { STRIPE_PUBLIC_KEY, STRIPE_MONTHLY_PRICE_ID } from '../../config';
import { getFullNameInversed } from '../../helpers/functions';
import {
    create as createSubscription,
    refreshSubscription,
} from '../../api/stripeSubscription';
import { setUser } from '../../redux/actions/auth';
import PriceElement from './PriceElement';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const priceLookupKey = STRIPE_MONTHLY_PRICE_ID;


const SubscribeForm = () => {
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    if (!stripe || !elements) {
        return <Spin spinning />;
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: getFullNameInversed(user),
                    email: user.email,
                },
            });

            if (error) {
                // show error and collect new card details.
                setLoading(false);
                message.error(error.message);
                return;
            }

            // Create the subscription.
            const { data: subscription } = await createSubscription({
                priceLookupKey,
                paymentMethodId: paymentMethod.id,
            });

            switch (subscription.status) {
                case 'active':
                    message.success('Succès ! Bienvenu sur AeroHealth.');
                    dispatch(
                        setUser({
                            ...user,
                            subscription: {
                                id: subscription.id,
                                status: subscription.status,
                            },
                        }),
                    );
                    break;
                case 'incomplete':
                    message.alert('Veuillez confirmer le paiement.');
                    const { error } = await stripe.confirmCardPayment(
                        subscription.latest_invoice.payment_intent.client_secret,
                    );
                    if (error) {
                        message.error(error.message);
                    } else {
                        dispatch(
                            setUser({
                                ...user,
                                subscription: { id: subscription.id, status: 'active' },
                            }),
                        );
                        message.success('Succès ! Redirection à votre compte.');
                        await refreshSubscription();
                    }
                    break;
                default:
                    message.error(
                        `Une erreur inconnue est survenue: ${subscription.status}`,
                    );
            }
        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    };

    return (
        <Spin spinning={loading}>
            <form>
                <PriceElement title="Pack Pro" price="79€ TTC" duration="par mois" />

                <div style={{ padding: 50 }}>
                    <CardElement />
                </div>
                <Button onClick={handleSubmit} type="primary" block size="large">
                    Valider
                </Button>
            </form>
        </Spin>
    );
};

const SubscriptionForm = props => (
    <Elements stripe={stripePromise}>
        <SubscribeForm {...props} />
    </Elements>
);

export default SubscriptionForm;
