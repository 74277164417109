export default {
    uploadButton: {
        fr: 'Télécharger',
        en: 'Upload',
    },
    cropTitle: {
        fr: "Recadrer l'image",
        en: 'Edit image',
    },
    cropCancel: {
        fr: 'Annuler',
        en: 'Cancel',
    },
    updateSuccess: {
        fr: 'Image modifiée avec succès.',
        en: 'Image uploaded successfully.',
    },
    updateError: {
        fr: "une erreur s'est produite veuillez reessayer.",
        en: 'An error has occured, please retry.',
    },
};
