/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card, Tag, Button, Modal,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import CustomAvatar from '../Avatar';
import {
    getFullName,
    formatDateForTelec,
    getUserInitials,
    formatDate,
} from '../../helpers/functions';
import {
    setActiveTelec,
    removeBookedConsultationAsync,
} from '../../redux/actions/consultations';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';

const lang = getLang();
const { Meta } = Card;

const notstartingNow = (date) => {
    const minutesLeft = DateTime.fromISO(date).diffNow('minutes').values.minutes;
    if (minutesLeft <= 15 && minutesLeft > -60) {
        return false;
    }
    return true;
};

const TelecCard = ({
    telec,
    showActions,
    setActiveTelec,
    removeBookedConsultationAsync,
    onClick,
    hoverable,
    onCancel,
}) => {
    const [startDisabled, setStartDisabled] = useState(notstartingNow(telec.date) || telec.paymentIntent === 'failure');

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const startingToday = DateTime.fromISO(telec.date).diffNow('hours').values.hours >= -8;
        const paymentFailed = telec.paymentIntent === 'failure';
        if (showActions && startDisabled && startingToday && !paymentFailed) {
            const intId = setInterval(() => {
                if (!notstartingNow(telec.date)) {
                    setStartDisabled(false);
                }
            }, 45000); // every 45 seconds
            return () => {
                clearInterval(intId);
            };
        }
    }, [startDisabled]);

    const formattedDate = formatDateForTelec(telec.date);

    const handleCancel = () => {
        Modal.confirm({
            title: TEXTS.cancelTitle[lang],
            icon: <ExclamationCircleOutlined />,
            content: TEXTS.cancelContent[lang],
            okText: TEXTS.cancelOkButton[lang],
            cancelText: TEXTS.cancelCancelButton[lang],
            okType: 'danger',
            onOk: () => {
                removeBookedConsultationAsync(telec._id);
                onCancel();
            },
        });
    };

    const actions = showActions
        ? [
            <Button type="danger" onClick={handleCancel}>
                {' '}
                {TEXTS.actionCancel[lang]}
            </Button>,
            <Button
                disabled={startDisabled}
                onClick={() => {
                    setActiveTelec(telec);
                    onCancel();
                }}
            >
                {TEXTS.actionStart[lang]}
            </Button>,
        ]
        : null;

    return (
        <Card
            id="dashboard-telec-element"
            style={{ margin: showActions ? '0 3px' : 0 }}
            hoverable={hoverable}
            onClick={onClick}
            actions={actions}
            title={(
                <div className="flex" style={{ alignItems: 'center' }}>
                    <CustomAvatar
                        initials={getUserInitials(telec.patient)}
                        size={30}
                        style={{ marginRight: 10 }}
                    />
                    <b>{getFullName(telec.patient)}</b>
                </div>
            )}
        >
            <p className="ant-card-meta-title">{`${TEXTS.birthDate[lang]}${formatDate(telec.patient.birthDate)}`}</p>
            <p className="ant-card-meta-title">{`${TEXTS.securityNumber[lang]}${telec.patient.securityNumber}`}</p>
            <Meta title={TEXTS.dateHour[lang]} description={formattedDate} />
            <div className="flex flex-wrap" style={{ marginTop: 10 }}>
                <div className="ant-card-meta-title">
                    {TEXTS.cPurpose[lang]}
                    {telec.cPurposes.length
                        ? telec.cPurposes.map(c => (
                            <Tag key={c} style={{ margin: 5 }} color="processing">
                                {c}
                            </Tag>
                        ))
                        : TEXTS.emptyCPurpose[lang]}
                </div>


            </div>
        </Card>
    );
};

TelecCard.propTypes = {
    telec: PropTypes.object.isRequired,
    removeBookedConsultationAsync: PropTypes.func.isRequired,
    setActiveTelec: PropTypes.func.isRequired,
    showActions: PropTypes.bool,
    onClick: PropTypes.func,
    hoverable: PropTypes.bool,
    onCancel: PropTypes.func,
};

TelecCard.defaultProps = {
    showActions: true,
    hoverable: true,
    onClick: () => {},
    onCancel: () => {},
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        setActiveTelec,
        removeBookedConsultationAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(TelecCard);
