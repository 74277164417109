import {
    SET_USER,
    SET_PATIENTS,
    UPDATE_PATIENT,
    SET_PRICING,
} from '../../actions/auth';

const initialState = {
    user: null,
    patients: [],
};

const auth = (state = initialState, action) => {
    console.log(action.payload);
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.payload };
        case SET_PRICING:
            return {
                ...state,
                user: {
                    ...state.user,
                    pricing: action.payload.pricing,
                    pricingNight: action.payload.pricingNight,
                    pricingWeekEnd: action.payload.pricingWeekEnd,
                },
            };
        case SET_PATIENTS:
            return { ...state, patients: action.payload };
        case UPDATE_PATIENT:
            return {
                ...state,
                patients: [
                    ...state.patients.filter(
                        patient => patient._id !== action.payload._id,
                    ),
                    action.payload,
                ],
            };
        default:
            return state;
    }
};

export default auth;
