export default {
    actionStart: {
        fr: 'Débuter',
        en: 'Start',
    },
    actionCancel: {
        fr: 'Annuler',
        en: 'Cancel',
    },
    birthDate: {
        fr: 'Né le ',
        en: 'Born the ',
    },
    securityNumber: {
        fr: 'Numéro de securité sociale : ',
        en: 'Security number : ',
    },
    dateHour: {
        fr: 'Date et heure : ',
        en: 'Date/Hour : ',
    },
    cPurpose: {
        fr: 'Motif de consultation : ',
        en: 'Reason for consultation : ',
    },
    emptyCPurpose: {
        fr: 'Aucun motif de consultation',
        en: '',
    },
    cancelTitle: {
        fr: "Annulation d'une téléconsultation",
        en: 'Cancellation of a teleconsultation',
    },
    cancelContent: {
        fr: 'Votre patient sera notifié par cette action, êtes vous sûre de bien vouloir annuler cette téléconsultation ?',
        en: 'Your patient will be notified by this action, Are you sure you want to cancel this téléconsultation ?',
    },
    cancelOkButton: {
        fr: "Confirmer l'annulation",
        en: 'Confirm the cancellation',
    },
    cancelCancelButton: {
        fr: 'Fermer',
        en: 'Close',
    },

};
