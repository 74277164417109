/* eslint-disable no-underscore-dangle */
import * as ConsultationsAPI from '../../../api/consultations';
import { sessionExpired } from '../auth';
import { setContentIsLoading } from '../loading';

export const SET_CONSULTATIONS_LIST = 'SET_CONSULTATIONS_LIST';
export const ADD_CONSULTATION = 'ADD_CONSULTATION';
export const REMOVE_CONSULTATION = 'REMOVE_CONSULTATION';
export const SET_ACTIVE_TELEC = 'SET_ACTIVE_TELEC';
export const SET_INCOMING_LIST = 'SET_INCOMING_LIST';
export const SET_STARTING_NOW = 'SET_STARTING_NOW';
export const REMOVE_INCOMING = 'REMOVE_INCOMING';
export const SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR';
export const SET_ITEM_CONSULTATION_DATE = 'SET_ITEM_CONSULTATION_DATE';

const setIncomingList = value => ({
    type: SET_INCOMING_LIST,
    payload: value,
});

const removeIncoming = consultation => ({
    type: REMOVE_INCOMING,
    payload: consultation,
});

const setConsultationsList = value => ({
    type: SET_CONSULTATIONS_LIST,
    payload: value,
});

const addConsultation = consultation => ({
    type: ADD_CONSULTATION,
    payload: consultation,
});

const setPaymentError = value => ({
    type: SET_PAYMENT_ERROR,
    payload: value,
});

const removeConsultation = id => ({
    type: REMOVE_CONSULTATION,
    payload: id,
});

const setActiveTelec = value => ({
    type: SET_ACTIVE_TELEC,
    payload: value,
});

const setStartingNow = value => ({
    type: SET_STARTING_NOW,
    payload: value,
});

const setItemConsultationDate = consultation => ({
    type: SET_ITEM_CONSULTATION_DATE,
    payload: consultation,
});

const setConsultationsListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.getAll();
        dispatch(setConsultationsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const setIncomingListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.getBooked();
        dispatch(setIncomingList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const createConsultationAsync = consultation => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.create(consultation);
        if (res.data) {
            dispatch(addConsultation(res.data));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const createManyAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.createMany(body);
        if (res.data) {
            console.log('[debug] createManyAsync - ', { res });
            dispatch(setConsultationsList(res.data));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const removeConsultationAsync = id => async (dispatch) => {
    try {
        const res = await ConsultationsAPI.remove(id);
        if (res.data) {
            dispatch(removeConsultation(id));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

const removeBookedConsultationAsync = id => async (dispatch) => {
    try {
        const res = await ConsultationsAPI.removeBooked(id);
        if (res.data) {
            dispatch(removeIncoming(id));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

const getTelecHistoryAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.getHistory();
        if (res.data) {
            dispatch(setConsultationsList(res.data));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const billTeleconsultationAsync = (consultationId, cb) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.billTeleconsultationById(consultationId);
        dispatch(setConsultationsList(res.data.data));
        cb();
    } catch (err) {
        sessionExpired(err, dispatch);
        cb(err);
    }
    dispatch(setContentIsLoading(false));
};

const cancelBillingTeleconsultationAsync = (
    consultationId,
    thirdPartyPayer,
    cb,
) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.cancelBillingTeleconsultationById(
            consultationId,
            thirdPartyPayer,
        );
        dispatch(setConsultationsList(res.data.data));
        cb();
    } catch (err) {
        sessionExpired(err, dispatch);
        cb(err);
    }
    dispatch(setContentIsLoading(false));
};

const refundTeleconsultationAsync = (consultationId, cb) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.refundTeleconsultationById(consultationId);
        dispatch(setConsultationsList(res.data.data));
        cb();
    } catch (err) {
        sessionExpired(err, dispatch);
        cb(err);
    }
    dispatch(setContentIsLoading(false));
};

const updateConsultationDateAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.updateDateConsultation(body);
        if (res.data) {
            dispatch(setItemConsultationDate(res.data));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export {
    setConsultationsList,
    setConsultationsListAsync,
    addConsultation,
    createConsultationAsync,
    removeConsultation,
    removeConsultationAsync,
    createManyAsync,
    setIncomingListAsync,
    setActiveTelec,
    getTelecHistoryAsync,
    billTeleconsultationAsync,
    cancelBillingTeleconsultationAsync,
    removeBookedConsultationAsync,
    setIncomingList,
    removeIncoming,
    setStartingNow,
    setPaymentError,
    updateConsultationDateAsync,
    setItemConsultationDate,
    refundTeleconsultationAsync,
};
