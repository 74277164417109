import axios from 'axios';
import { API_PHOTOS, API_REQ_HEADER } from '../config';

const PROFILE_PICTURE_NAME = 'profile-picture.webp';

// eslint-disable-next-line consistent-return
const addImages = async (userId, category, images) => {
    const data = new FormData();
    data.append('files', images);
    try {
        const res = await axios.post(
            `${API_PHOTOS}/upload/${userId}/${category}`,
            data,
            API_REQ_HEADER,
        );
        return res.data;
    } catch (err) {
        // nada
    }
};


const loadImage = (userId, category, resolution, fileName) => (
    `${API_PHOTOS}/load/${userId}/${category}/${resolution}/${fileName}?${Date.now()}`
);

const loadProfileImage = (userId, resolution) => (
    `${API_PHOTOS}/load/${userId}/profile/${resolution}/${PROFILE_PICTURE_NAME}?${Date.now()}`
);

const getFileName = fileUrl => fileUrl.split('/')[9].split('?')[0];


export {
    addImages, loadImage, loadProfileImage, getFileName,
};
