export default {
    calendar: {
        fr: 'Agenda',
        en: 'Calendar',
    },
    addButton: {
        fr: 'Ajouter un créneau de téléconsultation',
        en: 'Add a teleconsultation time slot',
    },
    booked: {
        fr: 'Reservé',
        en: 'Booked',
    },
    available: {
        fr: 'Libre',
        en: 'Available',
    },
    deleteAvailabilityTitle: {
        fr: "Supression d'une disponibilité",
        en: 'Deletion of a teleconsultation time slot',
    },
    deleteAvailabilityContent: {
        fr: 'êtes vous sure de bien vouloir supprimer cette disponibilité ?',
        en: 'Are you sure you want to delete this time slot ?',
    },
    deleteAvailabilityOkText: {
        fr: 'Oui',
        en: 'Yes',
    },
    deleteAvailabilityCancelText: {
        fr: 'Annuler',
        en: 'Cancel',
    },
    deleteAvailabilitySuccessMsg: {
        fr: 'Disponibilité supprimé avec succès.',
        en: 'Time slot removed successfully.',
    },
};
