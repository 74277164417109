/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import {
    SET_CONSULTATIONS_LIST,
    SET_INCOMING_LIST,
    ADD_CONSULTATION,
    REMOVE_CONSULTATION,
    REMOVE_INCOMING,
    SET_ACTIVE_TELEC,
    SET_STARTING_NOW,
    SET_PAYMENT_ERROR,
    SET_ITEM_CONSULTATION_DATE,
} from '../../actions/consultations';

const initialState = {
    consultationsList: [],
    incomingList: [],
    activeTelec: null,
    startingNow: null,
    paymentError: null,
};

const consultations = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONSULTATIONS_LIST:
            return { ...state, consultationsList: action.payload };
        case SET_INCOMING_LIST:
            return { ...state, incomingList: action.payload };
        case SET_PAYMENT_ERROR:
            return { ...state, paymentError: action.payload };
        case SET_STARTING_NOW:
            return { ...state, startingNow: action.payload };
        case SET_ACTIVE_TELEC:
            return { ...state, activeTelec: action.payload };
        case ADD_CONSULTATION:
            return {
                ...state,
                consultationsList: [
                    ...state.consultationsList,
                    action.payload,
                ].sort((a, b) => (a.date > b.date ? 1 : -1)),
            };
        case REMOVE_INCOMING:
            return {
                ...state,
                incomingList: state.incomingList.filter(
                    element => element._id !== action.payload,
                ),
            };
        case REMOVE_CONSULTATION:
            return {
                ...state,
                consultationsList: state.consultationsList.filter(
                    element => element._id !== action.payload,
                ),
            };
        case SET_ITEM_CONSULTATION_DATE: {
            return {
                ...state,
                consultationsList: [...state.consultationsList.filter(
                    e => e._id !== action.payload._id,
                ), action.payload],
            };
        }
        default:
            return state;
    }
};

export default consultations;
