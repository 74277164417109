export default {
    step0: {
        fr: 'Bienvenue',
        en: 'Welcome',
    },
    step1: {
        fr: 'Compte Stripe',
        en: 'Stripe Account',
    },
    step2: {
        fr: 'Honoraires',
        en: 'Paiment configuration',
    },
    step3: {
        fr: 'Signature',
        en: 'Signature',
    },
    step0ResultTitle: {
        fr: 'Veuillez valider votre adresse e-mail',
        en: 'Please verify your e-mail address',
    },
    step0ResultSubTitle: {
        fr: 'A fin de valider votre adresse e-mail, merci de bien vous rendre sur votre boîte de réception.',
        en: 'To valide your e-mail address, please consult your mailbox .',
    },
    step0StartButton: {
        fr: 'Commencer',
        en: 'Start',
    },
    step1Description1: {
        fr: 'Cette étape vous permet de configurer le compte bancaire sur lequel sera transféré le montant de vos honoraires de téléconsultation',
        en: 'This step allows you to configure the bank account to which teleconsultations fees will be transferred.',
    },
    step1Description2: {
        fr: 'Afin de vous garantir une sécurité et une transparence optimale sur l’ensemble des transactions effectuées à travers votre application, nous avons fait le choix de la solution Stripe Express',
        en: ' In order to guarantee optimal security and transparency for all transactions carried out through Aestheclic, we have chosen the Stripe Express solution to manage the paiement flow of teleconsultations.',
    },
    step1CTA: {
        fr: 'Créer mon compte Stripe Express',
        en: 'Create my Stripe Express account',
    },
    step2InputLabel: {
        fr: 'Veuillez préciser le montant de vos honoraires de téléconsultation',
        en: 'Please indicate your teleconsultation fees',
    },
    step2InputPlaceholder: {
        fr: 'Tarification',
        en: 'Fees',
    },
    step2Description1: {
        fr: 'Une commission fixe de 5 euros sur chaque transaction effectuée à travers l’application Aestheclic est facturée en supplément du montant de vos honoraires afin de couvrir nos frais de fonctionnement et de transaction.',
        en: 'A commission of 5 euros is invoiced in addition to your teleconsultation fees in order to cover our operations and transactions costs.',
    },
    step2Description2: {
        fr: 'En cas d’annulation d’une téléconsultation aucun frais ne sera facturé.',
        en: 'No costs will be billed in case of teleconsultation cancellation.',
    },
    step2CheckboxLabel: {
        fr: 'J’atteste avoir pris connaissance des conditions de facturation d’Aestheclic.',
        en: 'I certify that I have read the billing conditions of Aestheclic.',
    },
    lastResultProfileButton: {
        fr: 'Compléter mon profil',
        en: 'Complete my profile',
    },
    lastResultTitle: {
        fr: 'Félicitations !',
        en: 'Congratulations !',
    },
    lastResultSubTitle: {
        fr: 'Il ne vous reste plus qu’à configurer les éléments relatifs à votre profil (photo, langues parlées, cursus et biographie) Ces informations seront visibles par vos futurs patients.',
        en: 'All what you have to do now is to complete your profile (picture, spoken languages, social media, biography ...), these informations will be consulted by your future patients.',
    },
    nextButton: {
        fr: 'Suivant',
        en: 'Next',
    },
    step2PricingError: {
        fr: 'Veuillez préciser le montant de vos honoraires.',
        en: 'Please indicate your teleconsultation fees.',
    },
    step2ConsentError: {
        fr: 'Veuillez accepter nos conditions de facturation.',
        en: 'In order to continue, you need to accept the billing conditions of Aestheclic.',

    },
    AlreadyStripeAccount: {
        fr: 'Vous avez déja configurer votre compte stripe',
        en: 'You have already done with the configurtation of your stripe account.',
    },
};
