/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Tag,
    Breadcrumb,
    Table,
    Typography,
    Card,
    Button,
    Tooltip,
    message,
} from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import {
    getTelecHistoryAsync,
    billTeleconsultationAsync,
    cancelBillingTeleconsultationAsync,
    refundTeleconsultationAsync,
} from '../../../redux/actions/consultations';
import {
    formatDateForTelec,
    getFullName,
    formatDate,
} from '../../../helpers/functions';
import SectionHeader from '../../../components/SectionHeader';
import { getLang } from '../../../config/helpers';
import TEXTS from './_texts/index.texts';

const lang = getLang();

const { Title } = Typography;

const TelecHistory = ({
    getTelecHistoryAsync,
    billTeleconsultationAsync,
    cancelBillingTeleconsultationAsync,
    refundTeleconsultationAsync,
    consultationsList,
    contentIsLoading,
    user,
}) => {
    const columns = [
        {
            title: 'Patient',
            dataIndex: 'patient',
            key: 'patient',
            sorter: (a, b) => getFullName(a.patient) > getFullName(b.patient),
            render: patient => (patient && patient.lastName ? <span>{getFullName(patient)}</span> : null),
        },
        {
            title: TEXTS.birthDate[lang],
            dataIndex: 'patient',
            key: 'birthDate',
            render: patient => (patient && patient.birthDate ? <span>{formatDate(patient.birthDate)}</span> : null),
        },
        {
            title: TEXTS.securityNumber[lang],
            dataIndex: 'patient',
            key: 'securityNumber',
            render: patient => (patient && patient.securityNumber ? <span>{patient.securityNumber}</span> : null),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => a.date > b.date,
            render: date => <span>{formatDateForTelec(date)}</span>,
        },
        // {
        //     title: TEXTS.duration[lang],
        //     key: 'duration',
        //     render: (row) => {
        //         if (row && row.started && row.ended) {
        //             const start = DateTime.fromISO(row.started);
        //             const end = DateTime.fromISO(row.ended);
        //             const diff = end.diff(start, 'minutes').values.minutes;
        //             return <span>{diff}</span>;
        //         }
        //         return <span />;
        //     },TelecHistory
        // },
        {
            title: TEXTS.cPurpose[lang],
            dataIndex: 'cPurposes',
            key: 'cPurposes',
            render: cPurposes => (
                <>
                    {cPurposes.map(c => (
                        <Tag color="processing" key={c}>
                            {c.toUpperCase()}
                        </Tag>
                    ))}
                </>
            ),
            responsive: ['sm'],
        },
        {
            title: 'Statut',
            key: 'billingStatus',
            render: (text, record) => (
                <span>
                    {record.billableAt && !record.billedAt
                        ? 'En attente de facturation'
                        : record.billedAt && record.payments && record.payments.length > 0
                            ? 'Facturé'
                            : record.refundedAt
                                ? 'Remboursé'
                                : record.thirdPartyPayer
                                    ? 'Tier Payant'
                                    : ''}
                </span>
            ),
        },
        {
            title: 'Facturation',
            key: 'billingActions',
            render: (text, record) => (
                <>
                    <Tooltip title="Facturer Immédiatement">
                        <Button
                            shape="circle"
                            style={{ margin: '0 .5em' }}
                            disabled={
                                contentIsLoading || !record.billableAt || record.billedAt
                            }
                            onClick={() => {
                                billTeleconsultationAsync(record._id, (error) => {
                                    if (error) {
                                        message.error(
                                            'Une erreur est survenue lors du paiement. Veuillez réessayer ultérieurement.',
                                        );
                                    } else message.success('le paiement a été effectué avec succès.');
                                });
                            }}
                        >
                            F
                        </Button>
                    </Tooltip>
                    <Tooltip title="Tiers Payant">
                        <Button
                            shape="circle"
                            style={{ margin: '0 .5em' }}
                            disabled={
                                contentIsLoading || !record.billableAt || record.billedAt
                            }
                            onClick={() => {
                                cancelBillingTeleconsultationAsync(
                                    record._id,
                                    true,
                                    (error) => {
                                        if (error) {
                                            message.error(
                                                "Une erreur est survenue lors de l'annulation du paiement. Veuillez réessayer ultérieurement.",
                                            );
                                        } else message.success('le paiement a été annulé avec succès.');
                                    },
                                );
                            }}
                        >
                            T
                        </Button>
                    </Tooltip>
                    <Tooltip title="Rembourser">
                        <Button
                            shape="circle"
                            style={{ margin: '0 .5em' }}
                            disabled={
                                contentIsLoading || !record.billedAt || record.billableAt
                            }
                            onClick={() => {
                                refundTeleconsultationAsync(record._id, (error) => {
                                    if (error) {
                                        message.error(
                                            'Une erreur est survenue lors du remboursement. Veuillez réessayer ultérieurement.',
                                        );
                                    } else {
                                        message.success(
                                            'le remboursement a été effectué avec succès.',
                                        );
                                    }
                                });
                            }}
                        >
                            R
                        </Button>
                    </Tooltip>
                    <Tooltip title="Annuler">
                        <Button
                            shape="circle"
                            style={{ margin: '0 .5em' }}
                            disabled={
                                contentIsLoading || !record.billableAt || record.billedAt
                            }
                            onClick={() => {
                                cancelBillingTeleconsultationAsync(
                                    record._id,
                                    false,
                                    (error) => {
                                        if (error) {
                                            message.error(
                                                "Une erreur est survenue lors de l'annulation du paiement. Veuillez réessayer ultérieurement.",
                                            );
                                        } else message.success('le paiement a été annulé avec succès.');
                                    },
                                );
                            }}
                        >
                            A
                        </Button>
                    </Tooltip>
                </>
            ),
        },
    ];

    useEffect(() => {
        getTelecHistoryAsync();
    }, [user]);

    return (
        <div>
            <SectionHeader>
                <Breadcrumb style={{ marginBottom: '1.5rem' }}>
                    <Breadcrumb.Item>
                        <VideoCameraOutlined style={{ marginRight: 5 }} />
                        {TEXTS.breadCrumb1[lang]}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{TEXTS.breadCrumb2[lang]}</Breadcrumb.Item>
                </Breadcrumb>

                <div className="flex-column">
                    <Title level={4}>
                        {`${TEXTS.count[lang]} : ${consultationsList.length}`}
                    </Title>
                </div>
            </SectionHeader>
            <div className="section-content-container">
                <Card title={TEXTS.title[lang]} style={{ paddingBottom: '2.5em' }}>
                    <Table
                        columns={columns}
                        dataSource={consultationsList}
                        locale={{ emptyText: TEXTS.emptyList[lang] }}
                    />
                </Card>
            </div>
        </div>
    );
};

TelecHistory.propTypes = {
    consultationsList: PropTypes.array.isRequired,
    getTelecHistoryAsync: PropTypes.func.isRequired,
    billTeleconsultationAsync: PropTypes.func.isRequired,
    cancelBillingTeleconsultationAsync: PropTypes.func.isRequired,
    refundTeleconsultationAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    consultationsList: state.consultations.consultationsList,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getTelecHistoryAsync,
        billTeleconsultationAsync,
        cancelBillingTeleconsultationAsync,
        refundTeleconsultationAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(TelecHistory);
