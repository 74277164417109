import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { DateTime as dt } from 'luxon';
import { getLang } from '../../config/helpers';
import './styles.css';

const lang = getLang();

const formatEventName = (date) => {
    const d = new Date(date);
    const hours = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`;
    const minutes = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
    return `${hours}:${minutes}`;
};

const eventRender = (event, handleClickEvent) => (
    <Button
        type="primary"
        style={{ backgroundColor: event.patient ? '#ff4d4f' : '#52c41a', borderColor: event.patient ? '#ff4d4f' : '#52c41a' }}
        className="calendar-event-button"
        onClick={() => { handleClickEvent(event); }}>
        {formatEventName(event.date)}
    </Button>
);


const MapEvents = ({ events, handleClickEvent }) => {
    if (events.length > 0) {
        return (
            events.map((ev, j) => (
                <React.Fragment key={j.toString()}>
                    {eventRender(ev, handleClickEvent)}
                </React.Fragment>
            ))
        );
    }
    return null;
};

MapEvents.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string.isRequired,
    })).isRequired,
    handleClickEvent: PropTypes.func.isRequired,
};


const CalendarBody = ({
    dates, weekDays, handleClickEvent,
}) => (
    <div className="week week-events calendar-body-container">

        {dates.map((e, i) => (
            <div className="row day" key={i.toString()}>
                <div className={`day-${i} row day `} key={i.toString()}>
                    <div className="col-12 hidden-lg day-row show-sm">
                        <b>
                            {dt
                                .fromJSDate(e)
                                .setLocale(lang)
                                .toFormat('EEEE')
                                .toUpperCase()}
                        </b>
                        <br />
                        <b>
                            {dt
                                .fromJSDate(e)
                                .setLocale(lang)
                                .toLocaleString({ day: 'numeric', month: 'long' })
                                .toUpperCase()}
                        </b>
                    </div>
                </div>
                <div
                    className={`col-12 event-list-parent ${moment().format('L')
                            === moment(e).format('L') && 'today'}`}
                    style={{ order: (i + 1) * -1 }}
                >
                    <div className="row event-list">
                        <MapEvents
                            events={weekDays[i].events}
                            handleClickEvent={handleClickEvent} />
                    </div>
                </div>
            </div>
        ))}

    </div>
);


CalendarBody.propTypes = {
    dates: PropTypes.array.isRequired,
    weekDays: PropTypes.array.isRequired,
    handleClickEvent: PropTypes.func.isRequired,
};

export default CalendarBody;
