/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Typography, Button } from 'antd';
import SubscriptionForm from './SubscriptionForm';
import PriceElement from './PriceElement';

const { Paragraph, Text } = Typography;
const isAccountActivated = user => user.subscription && user.subscription.status === 'active';

const AccountActivation = ({ user }) => {
    const [open, setOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (user) {
            if (!isAccountActivated(user)) {
                setOpen(true);
            } else setOpen(false);
        }
    }, [user]);

    return (
        <Modal closable={false} title={null} visible={open} footer={null}>
            {showForm ? (
                <SubscriptionForm />
            ) : (
                <div>
                    <PriceElement title="Pack Pro" price="79€ TTC" duration="par mois" />
                    <Paragraph className="m-bottom-large m-top-large">
                        <Paragraph>
                            <Text strong>Pour les professionels de santé</Text>
                            {' '}
                            qui souhaitent
                            avoir une seule application pour gérer la consultation en vidéo ou
                            en cabinet
                        </Paragraph>
                    </Paragraph>

                    <Paragraph className="m-bottom-large m-top-large">
                        <Paragraph>
                            <Text strong>
                                Idéal pour les maisons de santé ou cabinet médicaux
                            </Text>
                            {' '}
                            qui aimeraient apporter une solution souple et communicante en
                            intégrant leurs outils métiers
                        </Paragraph>
                    </Paragraph>

                    <Paragraph className="m-bottom-large m-top-large">
                        <Paragraph>
                            <Text strong>Objectifs</Text>
                            {' '}
                            d&apos;améliorer le suivi médical,
                            d&apos;interagir avec ses patients et&nbsp;
                            <Text strong>simplifier votre quotidien</Text>
                        </Paragraph>
                    </Paragraph>
                    <Button
                        onClick={() => setShowForm(true)}
                        type="primary"
                        className="m-top-large"
                        size="large"
                        block
                    >
                        Procéder au paiement
                    </Button>
                </div>
            )}
        </Modal>
    );
};

AccountActivation.propTypes = {
    user: PropTypes.object,
};

AccountActivation.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps)(AccountActivation);
