export default {
    hangupButton: {
        fr: 'Terminer la téléconsultation',
        en: 'Finish the teleconsultation',
    },
    takePictureButton: {
        fr: 'Prendre le patient en photo',
        en: 'Take an image of your patient',
    },

    uploadOkText: { fr: 'Ajouter cette photo au dossier du patient', en: 'Add this picture to patients folder' },
    uploadCancelText: { fr: 'Annuler', en: 'Cancel' },
    uploadImageSuccess: { fr: 'Photo ajoutée avec succès.', en: 'Picutre added successfully.' },
    uploadImageError: { fr: "Une erreur s'est produite.", en: 'An error has occured.' },
};
