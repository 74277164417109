import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const CustomAvatar = (props) => {
    const {
        src, style, initials, ...rest
    } = props;
    return (
        <Avatar
            {...rest}
            style={{ backgroundColor: '#5f9bf1', ...style }}
            icon={initials ? null : <UserOutlined style={{ color: '#FFF' }} />}
            src={src}
        >
            {initials}
        </Avatar>
    );
};


CustomAvatar.propTypes = {
    src: PropTypes.string,
    style: PropTypes.object,
    initials: PropTypes.string,
};

CustomAvatar.defaultProps = {
    src: null,
    style: {},
    initials: null,
};


export default CustomAvatar;
