/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    Button, Typography, Form, Result,
} from 'antd';
import Peer from 'peerjs';
import {
    setActiveTelec,
    setIncomingList,
    setPaymentError,
    setStartingNow,
} from '../../redux/actions/consultations';
import { telecPaymentListener } from '../../redux/actions/notifications';
import LocalStreamPreview from './localStreamPreview';
import './styles.css';
import MediaControls from './mediaControls';
import history from '../../history';
import PlayStore from '../../components/Icons/GooglePlay';
import { getLang } from '../../config/helpers';
import { PEER_ENDPOINT } from '../../config';
import TEXTS from './_texts/index.texts';
import TelecTabs from './telecTabs';
import PatientInfoModal from './patientInfoModal';

const lang = getLang();
const { Title } = Typography;

const StartTelec = ({
    user,
    activeTelec,
    setActiveTelec,
    setIncomingList,
    setPaymentError,
    setStartingNow,
}) => {
    const [canStart, setCanStart] = useState();
    const [peer, setPeer] = useState();
    const [localStream, setLocalStream] = useState(null);
    const [activeConnection, setActiveConnection] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMsg, setNewMsg] = useState(null);
    const [showPatientInfo, setShowPatientInfo] = useState(false);
    const [messagesForm] = Form.useForm();

    // TODO refactor this func
    const scrollToBottom = () => {
        setTimeout(() => {
            const element = document.getElementById('messages-container');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }, 500);
    };

    const pushMessage = (message, sender) => {
        const content = {
            sender,
            content: message,
            time: new Date(),
        };

        setNewMsg(content);
    };

    useEffect(() => {
        if (newMsg) {
            const newMessages = [...messages];
            newMessages.push(newMsg);
            setMessages(newMessages);
            scrollToBottom();
        }
    }, [newMsg]);

    useEffect(() => {
        if (localStream) {
            const videoCaller = document.querySelector('#local-video-preview');
            videoCaller.srcObject = localStream;
            videoCaller.volume = 0;
        }
    }, [localStream]);

    useEffect(() => {
        if (canStart) {
            const peer = new Peer(`${user._id}-${activeTelec._id}`, PEER_ENDPOINT);
            const remoteId = activeTelec.patient._id;
            peer.on('open', () => {
                setPeer(peer);
                if (!activeConnection) {
                    const conn = peer.connect(`${remoteId}-${activeTelec._id}`);
                    conn.on('open', () => {
                        const calls = peer.call(
                            `${remoteId}-${activeTelec._id}`,
                            localStream,
                        );
                        calls.on('stream', (stream) => {
                            setRemoteStream(stream);
                        });
                        setActiveConnection(conn);
                        conn.on('data', (data) => {
                            pushMessage(data, remoteId);
                        });
                    });
                    conn.on('close', () => setActiveConnection(null));
                }
                peer.on('connection', (conn) => {
                    conn.on('close', () => setActiveConnection(null));
                    conn.on('data', (data) => {
                        pushMessage(data, remoteId);
                    });
                    peer.on('call', (call) => {
                        call.answer(localStream);
                        call.on('stream', (stream) => {
                            setRemoteStream(stream);
                        });
                    });

                    setActiveConnection(conn);
                });
            });
            const videoCaller = document.querySelector('#local-video');
            videoCaller.srcObject = localStream;
            videoCaller.volume = 0;
        }
    }, [canStart]);

    const endCall = () => {
        history.push('/t-history');
        localStream.getTracks().forEach(track => track.stop());
        setIncomingList([]);
        setActiveTelec(null);
        peer.destroy();
    };

    useEffect(() => {
        let errorListnerUnsub;
        if (canStart && remoteStream) {
            const videoReceiver = document.querySelector('#remote-video');
            videoReceiver.srcObject = remoteStream;

            const listenToErrors = async () => {
                errorListnerUnsub = await telecPaymentListener(user._id, () => {
                    endCall();
                    setStartingNow(null);
                    setPaymentError(true);
                });
            };
            listenToErrors();
        }

        return () => {
            if (errorListnerUnsub) {
                errorListnerUnsub();
            }
        };
    }, [remoteStream]);

    const handleCheckBox = (option) => {
        if (option === 'mic') {
            localStream.getAudioTracks()[0].enabled = !localStream.getAudioTracks()[0]
                .enabled;
        } else {
            localStream.getVideoTracks()[0].enabled = !localStream.getVideoTracks()[0]
                .enabled;
        }
    };

    const onFinishMessages = ({ messageToSend }) => {
        if (activeConnection && messageToSend.trim().length) {
            pushMessage(messageToSend, user._id);
            activeConnection.send(messageToSend);
            messagesForm.resetFields();
        }
    };

    if (window.innerWidth <= 600) {
        return (
            <Result
                status="403"
                title={TEXTS.resultTitle[lang]}
                subTitle={TEXTS.resultSubtitle[lang]}
                extra={(
                    <div className="flex-column" style={{ alignItems: 'center' }}>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.katomi.aestheclic"
                            target="_blank"
                            rel="noreferrer"
                            style={{ alignSelf: 'flex-start' }}
                        >
                            <Button size="large" className="googleplay-button">
                                <PlayStore style={{ width: 16, height: 16, marginRight: 5 }} />
                                <div className="flex-column">
                                    <small style={{ fontSize: 11 }}> Get it on </small>
                                    <span style={{ fontSize: 14 }}> Google play </span>
                                </div>
                            </Button>
                        </a>

                        <Button
                            onClick={() => {
                                setActiveTelec(null);
                            }}
                            type="primary"
                        >
                            {TEXTS.backButton[lang]}
                        </Button>
                    </div>
                )}
            />
        );
    }

    return (
        <div
            className="layout"
            style={{ minHeight: '100vh', height: 'max-content', overflow: 'auto' }}
        >
            {!canStart ? (
                <LocalStreamPreview
                    handleStart={() => setCanStart(true)}
                    localStream={localStream}
                    setLocalStream={setLocalStream}
                />
            ) : (
                <div className="flex" style={{ height: '100vh' }}>
                    <div style={{ flex: 6 }}>
                        <div className="flex-column">
                            <div
                                style={{
                                    height:
                    localStream && localStream.getVideoTracks().length > 0
                        ? '52vh'
                        : '80vh',
                                    marginBottom: 24,
                                }}
                            >
                                {activeConnection ? (
                                    <>
                                        <video id="remote-video" autoPlay />
                                        <canvas id="canvas" style={{ display: 'none' }} />
                                    </>
                                ) : (
                                    <Title
                                        level={4}
                                        style={{ textAlign: 'center', margin: '16%' }}
                                    >
                                        {TEXTS.interlocutorOffline[lang]}
                                    </Title>
                                )}
                            </div>
                            <div
                                style={{
                                    height:
                    localStream && localStream.getVideoTracks().length > 0
                        ? '35vh'
                        : '0px',
                                    alignItems: 'center',
                                }}
                                className="flex-column"
                            >
                                <video id="local-video" autoPlay />
                                <MediaControls
                                    handleCheckBox={handleCheckBox}
                                    canHangup
                                    onHangup={endCall}
                                    canTakePicture={activeConnection !== null}
                                />
                                <PatientInfoModal
                                    patientId={activeTelec.patient._id}
                                    open={showPatientInfo}
                                    handleClose={() => setShowPatientInfo(false)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex-column" style={{ flex: 2, maxWidth: '25vw' }}>
                        <TelecTabs
                            onFinishMessages={onFinishMessages}
                            messagesForm={messagesForm}
                            activeConnection={activeConnection}
                            messages={messages}
                            showPatientInfo={setShowPatientInfo}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

StartTelec.propTypes = {
    user: PropTypes.object.isRequired,
    activeTelec: PropTypes.object.isRequired,
    setActiveTelec: PropTypes.func.isRequired,
    setIncomingList: PropTypes.func.isRequired,
    setPaymentError: PropTypes.func.isRequired,
    setStartingNow: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    activeTelec: state.consultations.activeTelec,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setActiveTelec,
        setIncomingList,
        setPaymentError,
        setStartingNow,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(StartTelec);
