export default {
    resultTitle: {
        fr: 'Votre téléconsultation a été annulée',
        en: 'Your teleconsultation is cancelled',
    },
    resultSubtitle: {
        fr:
      "Nous avons annulé cette téléconsultation parce que votre patient n'as pas réussi a payer le montant de vos honoraires. Merci pour votre compréhension",
        en:
      'Your patient have failed to pay this teleconsultation, so we had to cancel it in order to avoid practicing teleconsultations for free',
    },
    extraButton: {
        fr: 'Retour au menu principal',
        en: 'I understand',
    },
};
