/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Typography, Card, Divider, Timeline,
} from 'antd';
import GuidedFilling from './GuidedFilling';
import SectionHeader from '../../components/SectionHeader';
import UserCard from '../../components/UserCard';
import StudiesForm from './StudiesForm';
import BiographyForm from './BiographyForm';
import SpokenLanguages from './SpokenLanguages';
import { updateUserAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import './styles.css';
import PricingForm from './pricingForm';

const lang = getLang();
const { Title, Paragraph } = Typography;

const Profile = ({ user, updateUserAsync }) => {
    useEffect(
        () => () => {
            updateUserAsync({ profileConfigComplete: true });
        },
        [],
    );

    const onUnmount = (vals) => {
        const { biography, ...socialMedia } = vals;
        updateUserAsync({ biography, socialMedia });
    };

    return (
        <div>
            <SectionHeader>
                <Paragraph>
                    <Title level={4}> Important: </Title>
                    <Paragraph>{TEXTS.secHeaderParagraph[lang]}</Paragraph>
                </Paragraph>
            </SectionHeader>

            <div className="section-content-container">
                {user.profileConfigComplete ? (
                    <Card id="profile-complete-card">
                        <Timeline>
                            <Timeline.Item>
                                <Title level={4} style={{ fontSize: 14, marginBottom: 24 }}>
                                    {TEXTS.profilePictureTitle[lang]}
                                </Title>
                                <UserCard showStat={false} />
                                <Divider />
                            </Timeline.Item>
                            <Timeline.Item>
                                <Title level={4} style={{ fontSize: 14, marginBottom: 24 }}>
                                    {TEXTS.spokenLanguagesTitle[lang]}
                                </Title>
                                <SpokenLanguages />
                                <Divider />
                            </Timeline.Item>
                            {/* PRICING */}
                            <Timeline.Item>
                                <Title level={4} style={{ fontSize: 14, marginBottom: 24 }}>
                                    {TEXTS.tarifsTitle[lang]}
                                </Title>
                                <PricingForm />
                                <Divider />
                            </Timeline.Item>
                            {/* PRICING END */}
                            <Timeline.Item>
                                <Title level={4} style={{ fontSize: 14, marginBottom: 24 }}>
                                    {TEXTS.academicBackgroundTitle[lang]}
                                </Title>
                                <StudiesForm />
                                <Divider />
                            </Timeline.Item>
                            <Timeline.Item>
                                <Title level={4} style={{ fontSize: 14, marginBottom: 24 }}>
                                    {TEXTS.BiographyNMediaTitle[lang]}
                                </Title>
                                <BiographyForm onUnmount={onUnmount} />
                            </Timeline.Item>
                            <Timeline.Item color="grey" />
                        </Timeline>
                    </Card>
                ) : (
                    <GuidedFilling />
                )}
            </div>
        </div>
    );
};

Profile.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Profile);
