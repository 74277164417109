import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form, Input, Button, message, Result,
} from 'antd';
import {
    GlobalOutlined,
    YoutubeFilled,
    InstagramFilled,
    FacebookFilled,
    LinkedinFilled,
} from '@ant-design/icons';
import { updateUserAsync } from '../../redux/actions/auth';
import { navigateTo } from '../../history';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/BiographyForm.texts';
import './styles.css';

const lang = getLang();
const { TextArea } = Input;

const BiographyForm = ({
    user, updateUserAsync, fromGuidedFilling, onUnmount,
}) => {
    const [showResult, setShowResult] = useState(false);
    const [standByValues, setStandByvalues] = useState(null);
    const [form] = Form.useForm();

    const onFinish = (values) => {
        const { biography, ...socialMedia } = { ...values };
        if (fromGuidedFilling) {
            setShowResult(true);
            setStandByvalues({ biography, socialMedia });
        } else {
            updateUserAsync({ biography, socialMedia });
            message.success(TEXTS.updateSuccess[lang]);
        }
    };

    const navigateToAgenda = () => {
        updateUserAsync(standByValues);
        navigateTo('/agenda');
    };

    useEffect(() => () => {
        const vals = form.getFieldsValue();
        onUnmount(vals);
    }, []);

    return (
        <>
            {showResult ? (
                <Result
                    className="initial-config-last-result"
                    status="success"
                    title={TEXTS.resultTitle[lang]}
                    subTitle={TEXTS.resultSubTitle[lang]}
                    extra={[
                        <Button
                            onClick={() => navigateToAgenda()}
                            type="primary"
                            key="profile"
                        >
                            {TEXTS.resultExtra[lang]}
                        </Button>,
                    ]}
                />

            ) : (
                <Form
                    form={form}
                    onFinish={onFinish}
                    size="large"
                    id="biography-form"
                    initialValues={{
                        biography: user.biography || '',
                        facebook: (user.socialMedia && user.socialMedia.facebook) || '',
                        linkedin: (user.socialMedia && user.socialMedia.linkedin) || '',
                        instagram: (user.socialMedia && user.socialMedia.instagram) || '',
                        youtube: (user.socialMedia && user.socialMedia.youtube) || '',
                        website: (user.socialMedia && user.socialMedia.website) || '',
                    }}
                >
                    <Form.Item name="biography">
                        <TextArea
                            autoSize={{ minRows: 3, maxRows: 10 }}
                            placeholder={TEXTS.bioPlaceholder[lang]}
                        />
                    </Form.Item>
                    <Form.Item name="website" className="item">
                        <Input prefix={<GlobalOutlined />} placeholder={TEXTS.web[lang]} />
                    </Form.Item>
                    {/* <Form.Item name="youtube" className="item">
                        <Input prefix={<YoutubeFilled />} placeholder={TEXTS.youtube[lang]} />
                    </Form.Item>
                    <Form.Item name="instagram" className="item">
                        <Input prefix={<InstagramFilled />} placeholder={TEXTS.instagram[lang]} />
                    </Form.Item>
                    <Form.Item name="facebook" className="item">
                        <Input prefix={<FacebookFilled />} placeholder={TEXTS.facebook[lang]} />
                    </Form.Item>
                    <Form.Item name="linkedin" className="item">
                        <Input prefix={<LinkedinFilled />} placeholder={TEXTS.linkedIn[lang]} />
                    </Form.Item> */}
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            size="large"
                            className="center-button"
                        >
                            {TEXTS.saveButton[lang]}
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>

    );
};

BiographyForm.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
    onUnmount: PropTypes.func,
    fromGuidedFilling: PropTypes.bool,
};

BiographyForm.defaultProps = {
    fromGuidedFilling: false,
    onUnmount: () => {},
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(BiographyForm);
