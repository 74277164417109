export default {
    breadCrumb: {
        fr: 'Profil',
        en: 'Profile',
    },
    secHeaderParagraph: {
        fr: 'Remplissez votre profil avec le plus grand soin, il sera consulté par vos patients.',
        en: 'Fill your profile with care, it will be consulted by your patients.',
    },
    profilePictureTitle: {
        fr: 'Photo de profil',
        en: 'Profile picture',
    },
    spokenLanguagesTitle: {
        fr: 'Langues parlées',
        en: 'Spoken languages',
    },
    academicBackgroundTitle: {
        fr: 'Cursus universitaire',
        en: 'Academic background',
    },
    BiographyNMediaTitle: {
        fr: 'Biographie',
        en: 'Biography',
    },
    tarifsTitle: {
        fr: 'Les tarifs',
        en: 'The rates',
    },


};
