export default {
    interlocutorOffline: {
        fr: "Votre interlocuteur n'est pas connecté, merci de patienter",
        en: 'Your interlocutor is offline...',
    },
    messagePlacholder: {
        fr: 'Tapez votre message',
        en: 'Type your message here',
    },
    sendMessage: {
        fr: 'Envoyer',
        en: 'Send',
    },
    resultTitle: {
        fr: "Vous n'êtes pas autorisé a continuer",
        en: 'You are not allowed to continue',
    },
    resultSubtitle: {
        fr: "Veuillez utiliser votre ordinateur ou télécharger l'application mobile pour effectuer votre téléconsultation.",
        en: 'You have to use the mobile application your computer in order to start your teleconsultation.',
    },
    backButton: {
        fr: 'Retour',
        en: 'Go back',
    },
    validatePrescription: {
        fr: 'Valider',
        en: 'Validate',
    },
    signatureUpdateMsg: {
        fr: 'Signature modifée avec succès.',
        en: 'Signature updated successfully.',
    },
    report: {
        fr: 'Compte Rendu',
        en: 'Teleconsultation Report',
    },
    draftReport: {
        fr: 'Enregistrer le brouillon',
        en: 'Save to draft',
    },
    submitReport: {
        fr: 'Partager avec le patient',
        en: 'Share it with the patient',
    },
    uploadSuccessMSG: {
        fr: 'Photo ajoutée avec succès.',
        en: 'Picture uploaded successfully.',
    },
    uploadErrorMSG: {
        fr: "Une erreur s'est produite.",
        en: 'An error has occured, please retry.',
    },
};
