import React, { useEffect, useState } from 'react';
import {
    Descriptions, message, Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { getById } from '../../api/patients';
import { getFullName } from '../../helpers/functions';


const PatientInfoModal = ({ patientId, open, handleClose }) => {
    const [patient, setPatient] = useState(null);

    useEffect(() => {
        const getPatientById = async () => {
            try {
                const { data } = await getById(patientId);
                setPatient(data);
            } catch (error) {
                message.error("Une erreur s'est produite lors, Veuillez réessayer");
            }
        };

        if (patientId && !patient) {
            getPatientById();
        }
    }, [patientId]);

    if (!patient) return null;

    return (
        <Modal footer={null} visible={open} onCancel={handleClose}>
            <div id="patient-info-modal">
                <Descriptions column={2} style={{ width: '100%' }}>
                    <Descriptions.Item span={2} label="Nom">
                        {patient.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Prénom">
                        {patient.firstName}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Date de naissance">
                        {DateTime.fromISO(patient.birthDate).toFormat('dd/MM/yyyy')}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Sexe">
                        {patient.gender}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Téléphone">
                        {patient.phoneNumber}
                    </Descriptions.Item>
                    {/* <Descriptions.Item span={2} label="Médecin Traitant">
                        {getFullName(patient.doctor)}
                    </Descriptions.Item> */}
                    <Descriptions.Item span={2} label="Numéro de sécurité sociale">
                        {patient.securityNumber}
                    </Descriptions.Item>
                </Descriptions>

            </div>
        </Modal>
    );
};

PatientInfoModal.propTypes = {
    patientId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default PatientInfoModal;
