/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
    Form, Input, Button, Checkbox, Divider, Row, Col,
} from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import PasswordRecovery from '../../components/PasswordRecovery';
import { loginAsync, recoverPassword } from '../../redux/actions/auth';
import './styles.css';
import FORM_RULES from '../../helpers/formRules';

const LoginForm = (props) => {
    const [visibleRecoverPW, setVisibleRecoverPW] = useState(false);

    const onFinish = async (values) => {
        const { loginAsync } = props;
        loginAsync(values);
    };

    const handlePWRecoverModal = () => {
        setVisibleRecoverPW(!visibleRecoverPW);
    };

    const recovrPW = async (email) => {
        await recoverPassword(email);
        handlePWRecoverModal();
    };

    return (
        <>
            <Form
                size="large"
                name="login-form"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item name="email" rules={[FORM_RULES.required, FORM_RULES.email]}>
                    <Input
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item name="password" rules={[FORM_RULES.required]}>
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Mot de passe"
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Rester connecté</Checkbox>
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        Se connecter
                    </Button>
                    <Divider />
                    <Button
                        onClick={() => {
                            handlePWRecoverModal();
                        }}
                        size="middle"
                        type="link"
                        className="center-button"
                    >
                        Mot de passe oublié?
                    </Button>
                </Form.Item>
            </Form>
            <PasswordRecovery
                visible={visibleRecoverPW}
                handleCancel={handlePWRecoverModal}
                handleOk={recovrPW}
                loading={false}
            />
            <Row>
                <Col span={24}>
                    {JSON.parse(localStorage.getItem('disconnected')) ? (
                        <p className="disconnect">
                            Vous avez été déconnecté pour cause d'inactivité.
                        </p>
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
        </>
    );
};

LoginForm.propTypes = {
    loginAsync: PropTypes.func.isRequired,
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        loginAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(LoginForm);
