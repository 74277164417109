/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Upload, Button, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import CustomAvatar from '../Avatar';
import { addImages, loadProfileImage } from '../../api/images';
import { getBase64 } from '../../helpers/functions';
import { updateUserAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import './styles.css';

const lang = getLang();

const ProfilePicture = ({
    user, onUpload, updateUserAsync, showUpload,
}) => {
    const [filesList, setFilesList] = useState([]);
    const [previewImage, setPreviewImage] = useState(
        loadProfileImage(user._id, 'preview'),
    );

    const uploadImage = async (file) => {
        if (
            !filesList[0]
      || file.lastModifiedDate !== filesList[0].lastModifiedDate
        ) {
            try {
                if (!file.url && !file.preview) {
                    // eslint-disable-next-line no-param-reassign
                    file.preview = await getBase64(file.originFileObj);
                }
                setPreviewImage(file.url || file.preview);
                addImages(user._id, 'profile', file.originFileObj);
                updateUserAsync({});
                message.success(TEXTS.updateSuccess[lang]);
                onUpload();
            } catch (err) {
                message.error(TEXTS.updateError[lang]);
            }
        }
    };

    return (
        <div className="flex-column profilePicture-container">
            <CustomAvatar size={80} src={previewImage} />

            {showUpload ? (
                <ImgCrop
                    rotate
                    modalTitle={TEXTS.cropTitle[lang]}
                    modalCancel={TEXTS.cropCancel[lang]}
                >
                    <Upload
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="text"
                        fileList={filesList}
                        onChange={({ file }) => {
                            setFilesList([file]);
                            uploadImage(file);
                        }}
                    >
                        <Button style={{ marginTop: 10 }}>
                            {TEXTS.uploadButton[lang]}
                        </Button>
                    </Upload>
                </ImgCrop>
            ) : null}
        </div>
    );
};

ProfilePicture.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
    onUpload: PropTypes.func,
    showUpload: PropTypes.bool,
};

ProfilePicture.defaultProps = {
    onUpload: () => {},
    showUpload: true,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ProfilePicture);
