/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Card, Typography } from 'antd';
import { MobileOutlined, DesktopOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { setStartingNow } from '../../redux/actions/consultations';
import { getLang } from '../../config/helpers';
import TelecCard from '../TelecCard';
import { navigateTo } from '../../history';
import TEXTS from './_texts/index.texts';
import './styles.css';

const lang = getLang();
const { Title, Text } = Typography;
const { Meta } = Card;

const notStartingNow = (date) => {
    const minutesLeft = DateTime.fromISO(date).diffNow('minutes').values.minutes;
    if (minutesLeft <= 15 && minutesLeft > -60) {
        return false;
    }
    return true;
};

const TelecModal = ({
    telec,
    handleClose,
    setStartingNow,
    open,
    pickDevice,
    user,
}) => {
    if (!telec) {
        return <> </>;
    }

    const [startDisabled, setStartDisabled] = useState(
        notStartingNow(telec.date) || telec.paymentIntent === 'failure',
    );
    const [showDeviceSelection, setShowDeviceSelection] = useState(
        pickDevice || false,
    );
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (user) {
            // TODO refactor this function
            const startingToday = DateTime.fromISO(telec.date).diffNow('hours').values.hours >= -8;
            let intId = null;
            const paymentError = telec.paymentIntent === 'failure';
            if (startingToday && startDisabled && !telec.started && !paymentError) {
                intId = setInterval(() => {
                    if (!notStartingNow(telec.date)) {
                        setStartDisabled(false);
                        if (!open) {
                            setStartingNow(telec);
                            clearInterval(intId);
                        }
                    }
                }, 45000);
                return () => {
                    clearInterval(intId);
                };
            }

            if (
                !startDisabled
        && !notStartingNow(telec.date)
        && !open
        && !telec.started
            ) {
                setStartingNow(telec);
                clearInterval(intId);
            }
        }
    }, [startDisabled]);

    return (
        <Modal
            wrapClassName={classnames({ 'telec-modal': !showDeviceSelection })}
            visible={open}
            footer={null}
            onCancel={handleClose}
        >
            {showDeviceSelection ? (
                <>
                    <Title level={4}>{TEXTS.selectDeviceTitle[lang]}</Title>
                    <Text>{TEXTS.selectedDeviceText[lang]}</Text>
                    <div
                        className="flex flex-wrap"
                        style={{ justifyContent: 'space-around', marginTop: 14 }}
                    >
                        {/* <Card
                            hoverable
                            onClick={() => {
                                handleClose();
                                navigateTo('/t-incoming');
                            }}
                            cover={<MobileOutlined className="device-icon" />}
                        >
                            <Meta title={TEXTS.mobileCard[lang]} />
                        </Card> */}
                        <Card
                            hoverable
                            onClick={() => {
                                setShowDeviceSelection(false);
                            }}
                            cover={<DesktopOutlined className="device-icon" />}
                        >
                            <Meta title={TEXTS.desktopCard[lang]} />
                        </Card>
                    </div>
                </>
            ) : (
                <TelecCard telec={telec} hoverable={false} onCancel={handleClose} />
            )}
        </Modal>
    );
};

TelecModal.propTypes = {
    telec: PropTypes.object.isRequired,
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    setStartingNow: PropTypes.func.isRequired,
    pickDevice: PropTypes.bool,
    user: PropTypes.object,
};

TelecModal.defaultProps = {
    pickDevice: false,
    user: null,
    open: false,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setStartingNow,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(TelecModal);
