import commonTexts from '../../../config/common.texts';

const { inputErrorRequired } = commonTexts;

export default {
    inputErrorRequired,
    pricing: {
        fr: 'Consultation vidéo',
        en: 'Video consultation',
    },
    pricingNight: {
        fr: 'Consultation vidéo de nuit (aprés 20h)',
        en: 'Night video consultation (after 8 p.m.)',
    },
    pricingWeekEnd: {
        fr: 'Consultation vidéo Samedi / Dimanche',
        en: 'Video consultation Saturday / Sunday',
    },
    addedSuccessfully: {
        fr: 'prix ajouté avec succès.',
        en: 'pricing degree added successfully.',
    },
    updatedSuccessfully: {
        fr: 'prix modifié avec succès.',
        en: 'pricing has been modified successfully.',
    },
    addButton: {
        fr: 'Enregistrer',
        en: 'Save',
    },
};
