import React, { useState } from 'react';
import { Typography, Divider, Button } from 'antd';
// import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import './styles.css';

const { Title } = Typography;

const Auth = () => (
    <div className="flex-column">
        <img
            src="ah-logo.svg"
            alt="wtver"
            className="first-image-auth"
        />
        <div className="auth-container flex-column">
            <img
                src="banner-img3.png"
                alt="wtver"
                className="signup-advertising-image"
            />
            <div className="signup-advertising flex-column">
                <Divider className="signup-divider" />
                {/* {showSignup ? <SignupForm /> : <LoginForm />} */}
                <LoginForm />
                {/* <Title
                        style={{ fontSize: 18, marginBottom: 18, textAlign: 'center' }}
                        level={3}
                    >
                        {showSignup ? 'Déja inscrit?' : 'Pas de compte?'}
                    </Title>

                    <Button
                        type="primary"
                        onClick={() => setShowSignup(!showSignup)}
                        style={{ maxWidth: '40%', marginBottom: '50px' }}
                        size="large"
                        className="center-button"
                    >
                        {showSignup ? 'Déja inscrit? Se connecter' : "S'inscrire maintenant"}
                        {' '}
                    </Button> */}
            </div>
        </div>
    </div>
);
export default Auth;
