export default {
    fr: [
        { value: 'en', label: 'Anglais' },
        { value: 'fr', label: 'Français' },
        { value: 'es', label: 'Espagnol' },
        { value: 'ar', label: 'Arabe' },
        { value: 'mr', label: 'Mandarin' },
        { value: 'gr', label: 'Allemand' },
        { value: 'jp', label: 'Japonais' },
        { value: 'tc', label: 'Turc' },
        { value: 'pt', label: 'Portugais' },
        { value: 'ru', label: 'Russe' },
        { value: 'it', label: 'Italien' },
    ],
    en: [
        { value: 'en', label: 'English' },
        { value: 'fr', label: 'French' },
        { value: 'es', label: 'Spanish' },
        { value: 'ar', label: 'Arabic' },
        { value: 'mr', label: 'Mandarin Chinese' },
        { value: 'gr', label: 'German' },
        { value: 'jp', label: 'Japanese' },
        { value: 'tc', label: 'Turkish' },
        { value: 'pt', label: 'Portuguese' },
        { value: 'ru', label: 'Russian' },
        { value: 'it', label: 'Italien' },
    ],
};

/*
    [
     {
         key: 'en',
         title: { fr: 'Anglais', en: 'English' },
     },
     {
         key: 'fr',
         title: { fr: 'Français', en: 'French' },
     },
     {
         key: 'es',
         title: { fr: 'Espagnol', en: 'Spanish' },
     },
     {
         key: 'ar',
         title: { fr: 'Arabe', en: 'Arabic' },
     },
     {
         key: 'mr',
         title: { fr: 'Mandarin', en: 'Mandarin Chinese' },
     },
     {
         key: 'gr',
         title: { fr: 'Allemand', en: 'German' },
     },
     {
         key: 'jp',
         title: { fr: 'Japonais', en: 'Japanese' },
     },
     {
         key: 'tc',
         title: { fr: 'Turc', en: 'Turkish' },
     },
     {
         key: 'pt',
         title: { fr: 'Portugais', en: 'portuguese' },
     },
     {
         key: 'ru',
         title: { fr: 'Russe', en: 'Russian' },
     },
]; */
