/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import {
    SELECT_CPURPOSE, SET_CPURPOSES_LIST, ADD_CPURPOSE, REMOVE_CPURPOSE,
} from '../../actions/purpose';

const initialState = {
    cPurposesList: [],
    selectedCPurpose: null,
};

const cPurpose = (state = initialState, action) => {
    switch (action.type) {
        case SET_CPURPOSES_LIST:
            return { ...state, cPurposesList: action.payload };
        case ADD_CPURPOSE:
            return {
                ...state,
                cPurposesList: [...state.cPurposesList, action.payload],
            };
        case SELECT_CPURPOSE:
            return { ...state, selectedCPurpose: action.payload };
        case REMOVE_CPURPOSE:
            return { ...state, cPurposesList: state.cPurposesList.filter(purpose => purpose._id !== action.payload) };
        default:
            return state;
    }
};

export default cPurpose;
