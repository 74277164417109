export default {
    breadCrumb: {
        fr: 'Accueil',
        en: 'Home',
    },
    profilCardTitle: {
        fr: 'Profil',
        en: 'Profile',
    },
    profilCardContent: {
        fr: 'Rempli',
        en: 'Filled',
    },
    completeProfile: {
        fr: 'Compléter mon profil',
        en: 'Complete my profile',
    },
    updateProfile: {
        fr: 'Modifier mon profil',
        en: 'Update my profile',
    },
    telecNumber: {
        fr: 'Téléconsultations effectuées',
        en: 'Accomplished teleconsultations',
    },
    prescriptionCardTitle: {
        fr: 'Mes prescriptions en attente',
        en: 'Pending prescriptions',
    },
    prescriptionCardEmpty: {
        fr: 'Aucune Prescription en attente.',
        en: 'No pending prescriptions.',
    },
    prescriptionCardElementDescription: {
        fr: 'Date de la téléconsultation :',
        en: 'Consultation date :',
    },
};
