/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form, Input, Button, Table, message, InputNumber,
} from 'antd';
import { updateUserAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/StudiesForm.texts';
import './styles.css';

const lang = getLang();

const columns = handleDelete => [
    {
        title: TEXTS.university[lang],
        dataIndex: 'university',
        key: 'university',
    },
    {
        title: TEXTS.diploma[lang],
        dataIndex: 'diploma',
        key: 'diploma',
        responsive: ['sm'],
    },
    {
        title: TEXTS.year[lang],
        dataIndex: 'year',
        key: 'year',
        responsive: ['sm'],
    },
    {
        title: 'Actions',
        dataIndex: 'key',
        key: 'delete',
        render: row => (
            <Button onClick={() => handleDelete(row.key)} type="link">
                {TEXTS.deleteButton[lang]}
            </Button>
        ),
    },
];

const StudiesForm = ({ user, updateUserAsync }) => {
    const [tableData, setTableData] = useState(user.studiesHistory || []);
    const [form] = Form.useForm();
    const onFinish = (values) => {
        const studiesHistory = [...tableData];
        studiesHistory.push({
            diploma: values.diploma,
            university: values.university,
            year: values.year,
            key: tableData.length,
        });
        updateUserAsync({ studiesHistory });
        message.success(TEXTS.addedSuccessfully[lang]);
        form.resetFields();
    };

    const handleDelete = (index) => {
        const studiesHistory = [...tableData];
        studiesHistory.splice(index, 1);
        updateUserAsync({ studiesHistory });
        message.success(TEXTS.deletedSuccessfully[lang]);
    };

    useEffect(() => {
        setTableData(user.studiesHistory || []);
    }, [user]);

    return (
        <div>
            <Form
                form={form}
                onFinish={onFinish}
                id="studies-form"
                layout="inline"
                className="flex flex-wrap"
                style={{ alignItems: 'flex-end' }}
            >
                <div className="flex studies-inputs-container">
                    <Form.Item
                        className="item"
                        name="university"
                        rules={[
                            {
                                required: true,
                                message: TEXTS.inputErrorRequired[lang],
                            },
                        ]}
                    >
                        <Input placeholder={TEXTS.university[lang]} />
                    </Form.Item>
                    <Form.Item
                        className="item diploma-input" // NOTE could be deleted
                        name="diploma"
                        rules={[
                            {
                                required: true,
                                message: TEXTS.inputErrorRequired[lang],
                            },
                        ]}
                    >
                        <Input placeholder={TEXTS.diploma[lang]} />
                    </Form.Item>
                    <Form.Item
                        name="year"
                        className="item studies-dates-input"
                        rules={[
                            {
                                required: true,
                                message: TEXTS.inputErrorRequired[lang],
                            },
                            {
                                validator: (_, value) => (value && value >= 1920 && value <= new Date().getFullYear()
                                    ? Promise.resolve()
                                    : Promise.reject(TEXTS.yearLimit[lang])),
                            },
                        ]}
                    >
                        <InputNumber placeholder={TEXTS.year[lang]} />
                    </Form.Item>
                </div>

                <Form.Item className="item add">
                    <Button htmlType="submit" type="primary">
                        {TEXTS.addButton[lang]}
                    </Button>
                </Form.Item>
            </Form>
            <Table
                className="studies-table"
                columns={columns(handleDelete)}
                dataSource={tableData}
            />
        </div>
    );
};

StudiesForm.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(StudiesForm);
