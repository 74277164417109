/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IdleTimer from 'react-idle-timer';
import { checkAuthAsync, disconnectAsync } from '../redux/actions/auth';
import {
    setStartingNow,
    setActiveTelec,
    setIncomingListAsync,
    setPaymentError,
} from '../redux/actions/consultations';
import Sidebar from '../components/Sidebar';
import AppHeader from '../components/AppHeader';
import './styles.css';
import Routes, { AuthRoutes } from '../routes';
import { isConfigComplete } from '../helpers/functions';
import history from '../history';
import StartTelec from '../sections/StartTeleconsultation';
import TelecModal from '../components/TelecModal';
import { notificationsListner } from '../redux/actions/notifications';
import PaymentError from '../components/PaymentError';
import AccountActivation from '../components/AccountActivation';

const { Content } = Layout;
const resolveCurrentUrl = () => history.location.pathname.split('/')[1] || '';
const isLoginRoute = () => resolveCurrentUrl() === 'auth';

const showSidebar = !isLoginRoute();

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeout: 60000 * 30, // 60000 * MINUTES
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            oldLocation: null,
            isListeningToNotifs: null,
        };

        this.idleTimer = null;
    }

   _onAction = (e) => {
       this.setState({ isTimedOut: false });
   }

  _onActive = (e) => {
      this.setState({ isTimedOut: false });
  }

  _onIdle = (e) => {
      const { disconnectAsync } = this.props;
      const current = history.location.pathname;
      const isTimedOut = this.state.isTimedOut;
      if (isTimedOut && current !== '/auth') {
          localStorage.setItem('disconnected', true);
          disconnectAsync();
      } else {
          this.setState({ showModal: true });
          this.idleTimer.reset();
          this.setState({ isTimedOut: true });
      }
  }

  componentDidMount() {
      const { checkAuthAsync, user, setActiveTelec } = this.props;
      checkAuthAsync();
      if (showSidebar && !user) {
          const oldLocation = history.location.pathname;
          if (oldLocation) {
              this.setState({ oldLocation });
          }
          history.replace('/auth');
      }

      history.listen((location) => {
          const { activeTelec, paymentError, setPaymentError } = this.props;
          if (location.pathname !== '/start' && activeTelec) {
              setActiveTelec(null);
          }
          if (paymentError) {
              setPaymentError(false);
          }
          this.setState({
              oldLocation: location.pathname,
          });
      });
  }

  componentDidUpdate(props) {
      const { user, activeTelec } = this.props;
      const current = history.location.pathname;

      if (user && current === '/auth') {
          if (!isConfigComplete(user)) {
              history.replace('/config');
          } else {
              history.replace('/dashboard');
          }
      }
      if (
          user
      && isConfigComplete(user) && user.email_verified
      && (current === '/config' || current === '/verif-email')
      ) {
          history.replace('/dashboard');
      }

      if (props.user !== user) {
          this.authCheck();
      }

      if (props.activeTelec !== activeTelec) {
          if (activeTelec) {
              history.replace('/start');
          }
      }
  }

  authCheck = async () => {
      const {
          user,
          activeTelec,
          incomingList,
          setIncomingListAsync,
      } = this.props;
      const { oldLocation, isListeningToNotifs } = this.state;
      let notifListner;
      if (user) {
          if (!isConfigComplete(user)) {
              history.replace('/config');
          } else if (!user.email_verified) {
              history.replace('/verif-email');
          } else {
              switch (oldLocation) {
                  case '/auth':
                      history.replace('/dashboard');
                      break;
                  case null:
                  case undefined:
                  case '/':
                      history.replace('/dashboard');
                      break;
                  case '/start':
                      if (!activeTelec) history.replace('/dashboard');
                      break;
                  default:
                      history.replace(oldLocation);
              }
              if (!incomingList.length) {
                  setIncomingListAsync();
              }
          }

          if (!isListeningToNotifs) {
              const { notificationsListner } = this.props;
              notifListner = await notificationsListner(
                  user._id,
                  setIncomingListAsync,
              );
              this.setState({ isListeningToNotifs: true });
          }
      } else {
          history.replace('/auth');
          if (notifListner) {
              notifListner();
          }
      }
      this.forceUpdate();
  };

  handleClickItem = (route) => {
      history.push(route);
  };

  render() {
      const {
          user,
          appIsLoading,
          activeTelec,
          incomingList,
          telecStartingNow,
          setStartingNow,
          paymentError,
      } = this.props;
      const { oldLocation } = this.state;
      if (appIsLoading) {
          return (
              <div style={{ width: '100vw', height: '100vh' }}>
                  <Spin
                      spinning
                      size="large"
                      style={{ margin: '20% auto', display: 'block' }}
                  />
              </div>
          );
      }
      if (activeTelec) return <StartTelec />;
      if (paymentError) return <PaymentError />;

      return (
          <>
              <IdleTimer
                  ref={(ref) => {
                      this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this._onAction}
                  onIdle={this._onIdle}
                  onAction={this._onActive}
                  debounce={250}
                  timeout={this.state.timeout}
              />
              {user ? (
                  <Layout>
                      {user && <Sidebar currentRoute={oldLocation} />}
                      <Layout className="layout-user">
                          {user && (
                              <AppHeader
                                  currentRoute={oldLocation}
                                  headerStyle={{ backgroundColor: '#fff' }}
                              />
                          )}
                          <Content className="app-content">
                              <Routes />
                              {incomingList.map(telec => (
                                  <TelecModal
                                      // eslint-disable-next-line no-underscore-dangle
                                      key={telec._id}
                                      pickDevice
                                      open={telecStartingNow !== null}
                                      telec={telecStartingNow || telec}
                                      handleClose={() => {
                                          setStartingNow(null);
                                      }}
                                  />
                              ))}
                              {/* <AccountActivation /> */}
                          </Content>
                      </Layout>
                  </Layout>
              ) : (
                  <Layout className="layout-visiter">
                      <AppHeader />
                      <Content className="app-content app-visitor">
                          <AuthRoutes />
                      </Content>
                  </Layout>
              )}
          </>
      );
  }
}

App.propTypes = {
    user: PropTypes.object,
    checkAuthAsync: PropTypes.func.isRequired,
    appIsLoading: PropTypes.bool.isRequired,
    setStartingNow: PropTypes.func.isRequired,
    activeTelec: PropTypes.object,
    telecStartingNow: PropTypes.object,
    setActiveTelec: PropTypes.func.isRequired,
    setIncomingListAsync: PropTypes.func.isRequired,
    incomingList: PropTypes.array.isRequired,
    paymentError: PropTypes.bool,
    setPaymentError: PropTypes.func.isRequired,
    notificationsListner: PropTypes.func.isRequired,
};

App.defaultProps = {
    user: null,
    activeTelec: null,
    telecStartingNow: null,
    paymentError: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    appIsLoading: state.loading.appIsLoading,
    activeTelec: state.consultations.activeTelec,
    paymentError: state.consultations.paymentError,
    telecStartingNow: state.consultations.startingNow,
    incomingList: state.consultations.incomingList,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        checkAuthAsync,
        setStartingNow,
        setActiveTelec,
        setIncomingListAsync,
        setPaymentError,
        notificationsListner,
        disconnectAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(App);
