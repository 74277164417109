import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Modal, Form, DatePicker,
    Button, message, ConfigProvider,
    Input,
} from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { createManyAsync, createConsultationAsync } from '../../redux/actions/consultations';
import { getLang } from '../../config/helpers';
import FORM_RULES from '../../helpers/formRules';
import TEXTS from './_texts/add.texts';
import 'moment/locale/fr';
import './styles.css';

const lang = getLang();
moment.locale(lang);

const disabledDate = current => current && current < moment().endOf('day').subtract(1, 'days');

const AddModal = ({
    open, handleClose, createConsultationAsync,
}) => {
    const [form] = Form.useForm();

    const [singleValues, setSingleValues] = useState({
        date: null,
        timeRange: [],
    });


    useEffect(() => {
        if (!open) {
            form.resetFields();
        }
    }, [open]);


    const handleSingleEvent = async () => {
        await form.validateFields();
        const date = DateTime.fromISO(`${singleValues.date}T${form.getFieldValue('singleTimeRange')}`);
        createConsultationAsync({ date });
        message.success(TEXTS.singleAddSuccess[lang]);
        handleClose();
    };


    return (
        <Modal
            onCancel={handleClose}
            footer={null}
            title={TEXTS.title[lang]}
            visible={open}
        >
            <ConfigProvider locale={TEXTS.configLocale[lang]}>
                <Form
                    onFinish={handleSingleEvent}
                    form={form}
                >
                    <Form.Item
                        name="singleDate"
                        rules={[
                            {
                                required: true,
                                message: TEXTS.inputErrorRequired[lang],
                            },
                        ]}
                        label="Date">
                        <DatePicker
                            onChange={(_, val) => {
                                setSingleValues({ ...singleValues, date: val });
                            }}
                            disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item
                        name="singleTimeRange"
                        rules={[
                            {
                                required: true,
                                message: TEXTS.inputErrorRequired[lang],
                            },
                            FORM_RULES.time,
                        ]}
                        label={TEXTS.startTime[lang]}>
                        <Input
                            style={{ width: '100px' }}
                            placeholder="HH:mm" />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            htmlType="submit"
                            type="primary"
                            className="center-button"
                        >
                            {TEXTS.submitButton[lang]}
                        </Button>
                    </Form.Item>
                </Form>
            </ConfigProvider>
        </Modal>
    );
};

AddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    createConsultationAsync: PropTypes.func.isRequired,
};


const dispatchToProps = dispatch => bindActionCreators(
    {
        createManyAsync,
        createConsultationAsync,
    },
    dispatch,
);


export default connect(null, dispatchToProps)(AddModal);
