import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title, Paragraph } = Typography;

const PriceElement = ({ title, duration, price }) => (
    <>
        <Title level={1} className="center-text" style={{ color: '#f04842' }}>
            {title}
        </Title>
        <Paragraph className="flex justify-c-center">
            <Title level={3}>{price}</Title>
            <Title type="secondary" level={5}>
                {duration}
            </Title>
        </Paragraph>
    </>
);

PriceElement.propTypes = {
    title: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
};


export default PriceElement;
