import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

const SectionHeader = ({ children }) => (
    <Card className="m-bottom-large" id="section-header-card">
        {children}
    </Card>
);


SectionHeader.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SectionHeader;
