import React from 'react';
import {
    ExclamationCircleOutlined, FieldTimeOutlined,
} from '@ant-design/icons';
import { message, Modal } from 'antd';
import moment from 'moment';
import TEXTS from '../_texts/index.texts';
import { getLang } from '../../../config/helpers';

const lang = getLang();
const { confirm } = Modal;

export const esLocale = {
    code: 'fr',
    week: {
        dow: 1, // Sunday is the first day of the week.
        doy: 7, // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
        prev: 'Précedent',
        next: 'Suivant',
        today: 'Aujourdhui',
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        list: 'Agenda',
    },
    weekText: 'Semaine prochaine',
    allDayText: 'Tous les jours',
    moreLinkText: 'Suite',
    noEventsText: "Il n'y a aucun événement à afficher",
};

export const headerToolCalendar = {
    left: 'prev,next today',
    center: 'title',
    right: 'timeGridWeek,timeGridDay',
};

/**
 *
 * @param {String} type
 * @param {String} id
 * @param {Object} arg
 * @param {Function} func
 *
 * this function called when user click on an event ( Delete or add ) a time for consultation
 */
export const confirmEvent = (type, id, arg, func) => {
    confirm({
        title: type === 'danger' ? TEXTS.deleteAvailabilityTitle[lang] : TEXTS.addAvailabilityTitle[lang],
        icon: type === 'danger' ? <ExclamationCircleOutlined /> : <FieldTimeOutlined />,
        content: type === 'danger' ? TEXTS.deleteAvailabilityContent[lang] : TEXTS.addAvailabilityContent[lang],
        okText: TEXTS.deleteAvailabilityOkText[lang],
        okType: type === 'danger' ? 'danger' : 'info',
        onOk: () => {
            if (type === 'danger') {
                func(id);
                message.success(TEXTS.deleteAvailabilitySuccessMsg[lang]);
            } else {
                func({ date: arg.dateStr, endHourDate: moment(arg.dateStr).add(25, 'minutes') });
                message.success(TEXTS.addAvailabilitySuccessMsg[lang]);
            }
        },
        cancelText: TEXTS.deleteAvailabilityCancelText[lang],
    });
};
