import {
    Form, Input, Modal, Upload, Button, message, Select,
} from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UploadOutlined } from '@ant-design/icons';
import { checkAuthAsync, deleteImageAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/upload.texts';
import { addImages } from '../../api/images';

const lang = getLang();
const Option = Select.Option;
const categories = [
    { value: 'ORDONNANCES', label: 'ORDONNANCES' },
    { value: 'ALLERGIES', label: 'ALLERGIES' },
    { value: 'ANTÉCÉDENTS', label: 'ANTÉCÉDENTS' },
    { value: 'EXAMENS IMAGERIE', label: 'EXAMENS IMAGERIE' },
    { value: 'CONSULTATIONS', label: 'CONSULTATIONS' },
    { value: 'VACCINS', label: 'VACCINS' },
    { value: 'EXAMENS BIOLOGIQUE', label: 'EXAMENS BIOLOGIQUE' },
    { value: 'ENTRETIENS PHARMACEUTIQUE', label: 'ENTRETIENS PHARMACEUTIQUE' },
];

const UploadModal = (props) => {
    const {
        visible, onCancel, onCreate, user,
    } = props;
    const [form] = Form.useForm();

    const uploadImage = async (file) => {
        try {
            await addImages(user._id, 'photos', file);
            checkAuthAsync();
            message.success(TEXTS.uploadSuccessMSG[lang]);
        } catch (err) {
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    // const uploadDocument = async (file) => {
    //     try {
    //         await addDocuments(user._id, 'documents', file);
    //         checkAuthAsync();
    //         message.success(TEXTS.uploadSuccessMSG[lang]);
    //     } catch (err) {
    //         message.error(TEXTS.uploadErrorMSG[lang]);
    //     }
    // };
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Modal
            title="Ajout d'un document"
            visible={visible}
            okText="Ajouter"
            cancelText="Annuler"
            onOk={() => {
                form
                    .validateFields()
                    .then(async (values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch(() => {
                        // console.log('Validate Failed:', info);
                    });
            }}
            onCancel={onCancel}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item label="Categoie du document" name="categorie" rules={[{ required: true }]} style={{ width: '100%' }}>
                    <Select
                        showSearch
                        placeholder="Selectionnez une categorie"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            categories.map(item => <Option value={item.value}>{item.label}</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="documents"
                    label="Ajoutez vos documents"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra="liste des documents ajoutés"
                    rules={[{ required: true }]}
                >
                    <Upload name="document" listType="documents">
                        <Button icon={<UploadOutlined />}>importer document</Button>
                    </Upload>
                </Form.Item>

            </Form>
        </Modal>
    );
};

UploadModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    checkAuthAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        checkAuthAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(UploadModal);
