import auth from './auth';
import consultations from './consultations';
import loading from './loading';
import cPurpose from './purpose';


export default {
    purposes: cPurpose,
    auth,
    consultations,
    loading,
};
