import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
    Button, Card,
} from 'antd';
import {
    PlusCircleOutlined, SettingOutlined,
} from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import TEXTS from './_texts/index.texts';
import { getLang } from '../../config/helpers';
import AddConfiuration from '../Agenda/AgendaConfig';
import AddNewTimeConsulutation from './add/addNewTimeConsulutation';
import {
    setConsultationsListAsync,
    removeConsultationAsync,
    setConsultationsList,
    createConsultationAsync,
    updateConsultationDateAsync,
} from '../../redux/actions/consultations';
import './calendar.css';
import PreviewEvent from './previewEvent';
import { esLocale, confirmEvent, headerToolCalendar } from './helper';

const lang = getLang();


const Calendar = ({
    user,
    consultations,
    setConsultationsListAsync,
    removeConsultationAsync,
    setConsultationsList,
    createConsultationAsync,
    updateConsultationDateAsync,
}) => {
    const [showConfig, setShowConfig] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedEvent, setSelectEvent] = useState(null);

    const handleDateClick = (arg) => {
        const datenow = new Date();
        const formattedDatenow = moment(datenow).format();
        if (moment(arg.dateStr).isAfter(formattedDatenow)) {
            const type = 'info';
            confirmEvent(type, null, arg, createConsultationAsync);
        }
    };
    const onCreate = () => {
    };

    useEffect(() => {
        setConsultationsListAsync();
    }, [user]);

    useEffect(
        () => () => {
            setConsultationsList([]);
        },
        [],
    );
    const dropEvent = (e) => {
        const {
            start: newStart,
            end: newEnd,
        } = e.event._instance.range;
        if (moment(new Date()).isAfter(newStart)) {
            e.revert();
        } else {
            updateConsultationDateAsync({ id: e.event.id, newStart: e.event.startStr, newEnd: e.event.endStr });
        }
    };
    const handleClickEvent = (info) => {
        const { id, extendedProps } = info.event;
        if (extendedProps.rdvPrise) {
            setOpenPreview(true);
            setSelectEvent(extendedProps.event);
        } else {
            const type = 'danger';
            confirmEvent(type, id, null, removeConsultationAsync);
        }
    };

    if (showConfig) return <AddConfiuration handleGoBack={() => setShowConfig(false)} />;

    return (
        <div>
            <Card title="Configuration d'agenda" style={{ width: '100%', padding: '50px' }}>
                <div className="flex">
                    <Button
                        className="m-right-medium"
                        onClick={() => setShowConfig(!showConfig)}
                        type="primary"
                        icon={<SettingOutlined />}
                    >
                        Configurer votre agenda
                    </Button>
                    <Button
                        onClick={() => setOpenAdd(!openAdd)}
                        type="primary"
                        icon={<PlusCircleOutlined />}
                    >
                        {TEXTS.addButton[lang]}
                    </Button>
                </div>
                <AddNewTimeConsulutation open={openAdd} handleClose={() => setOpenAdd(false)} />
                {/* <AddConfiuration
                    visible={showConfig}
                    onCreate={onCreate}
                    onCancel={() => {
                        setShowConfig(false);
                    }}
                /> */}
                <div style={{ width: '100%' }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                        headerToolbar={headerToolCalendar}
                        slotMinTime="00:00"
                        slotMaxTime="23:59"
                        slotDuration="00:10:00"
                        slotLabelInterval="10"
                        locales={[esLocale]}
                        initialView="timeGridWeek"
                        contentHeight="auto"
                        dateClick={handleDateClick}
                        events={consultations.map(e => ({
                            title: e.patient ? `${e.patient.firstName} ${e.patient.lastName}` : moment(e.date).format('LL'),
                            date: e.date,
                            end: e.endHourDate,
                            id: e._id,
                            backgroundColor: e.patient ? '#ff4d4f' : null,
                            borderColor: e.patient ? '#ff4d4f' : '#52c41a',
                            rdvPrise: !!e.patient,
                            event: e,
                        }))
                        }
                        eventDurationEditable={false}
                        editable
                        droppable
                        eventDrop={dropEvent}
                        eventClick={handleClickEvent}
                    />
                </div>
                {openPreview && selectedEvent != null ? (
                    <PreviewEvent
                        event={selectedEvent}
                        handleClose={() => {
                            setOpenPreview(false);
                            setSelectEvent(null);
                        }}
                        open={openPreview}
                    />
                ) : null}
            </Card>

        </div>
    );
};
Calendar.propTypes = {
    consultations: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    setConsultationsListAsync: PropTypes.func.isRequired,
    removeConsultationAsync: PropTypes.func.isRequired,
    setConsultationsList: PropTypes.func.isRequired,
    createConsultationAsync: PropTypes.func.isRequired,
    updateConsultationDateAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    consultations: state.consultations.consultationsList,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setConsultationsListAsync,
        removeConsultationAsync,
        setConsultationsList,
        createConsultationAsync,
        updateConsultationDateAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Calendar);
