/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Breadcrumb,
} from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import SectionHeader from '../../components/SectionHeader';
import TelecList from './incomingTelecList';
import UserCard from '../../components/UserCard';
import { getHistory } from '../../api/consultations';
import TEXTS from './_texts/index.texts';
import { getLang } from '../../config/helpers';
import './styles.css';

const lang = getLang();

const Dashboard = ({ user }) => {
    const [telecNumber, setTelecNumber] = useState(null);

    useEffect(() => {
        const updateRate = async () => {
            try {
                const historyRes = await getHistory();
                setTelecNumber(historyRes.data.length);
            } catch (err) {
                // nada
            }
        };
        updateRate();
    }, [user]);


    return (
        <div>
            <SectionHeader>
                <Breadcrumb style={{ marginBottom: '1.5rem' }}>
                    <Breadcrumb.Item>
                        <DashboardOutlined style={{ marginRight: 5 }} />
                        {TEXTS.breadCrumb[lang]}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <UserCard showUpload={false} telecNumber={telecNumber} />
            </SectionHeader>
            <div className="section-content-container">
                <div
                    className="flex flex-wrap"
                    style={{ alignItems: 'first baseline' }}
                >
                    <TelecList />
                </div>

            </div>
        </div>
    );
};

Dashboard.propTypes = {
    user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Dashboard);
