export default {
    resultTitle: {
        fr: 'Félicitations !',
        en: 'Congratulations !',
    },
    resultSubTitle: {
        fr: "Votre profile à été bien configuré, votre derniére étape est d'ajouter des créneaux de téléconsultations dans votre agenda.",
        en: 'You have successfully filled your profile, the last step is to add your availabilities time slots for teleconsultations in your calendar. ',
    },
    resultExtra: {
        fr: 'Ajouter des créneaux de téléconsultation',
        en: 'Add my teleconsultations time slots',
    },
    bioPlaceholder: {
        fr: 'Décrivez en quelques lignes votre parcours professionnel, vos domaines d’expertises, un message personnalisé destiné à vos patients...',
        en: 'Describe in a few lines your professional career, your areas of expertise, write a personalized message for your patients...',
    },
    web: {
        fr: 'Site web',
        en: 'Website',
    },
    youtube: {
        fr: 'Chaine Youtube',
        en: 'Youtube channel',
    },
    instagram: {
        fr: 'Profil Instagram',
        en: 'Instagram profile',
    },
    facebook: {
        fr: 'Page Facebook',
        en: 'Facebook page',
    },
    linkedIn: {
        fr: 'Profil LinkedIn',
        en: 'LinkedIn profile',
    },
    saveButton: {
        fr: 'Enregistrer',
        en: 'Save',
    },
    updateSuccess: {
        fr: 'Profil modifié avec succès.',
        en: 'Profile updated successfully.',
    },
};
