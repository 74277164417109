/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card, Button, Badge, Modal, message,
} from 'antd';
import { ExclamationCircleOutlined, PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import Calendar from '../../components/Calendar';
import AddModal from './add';
import PreviewEvent from './previewEvent';
import {
    setConsultationsListAsync,
    removeConsultationAsync,
    setConsultationsList,
} from '../../redux/actions/consultations';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import './styles.css';
import AgendaConfig from './AgendaConfig';

const lang = getLang();
const { confirm } = Modal;

const Agenda = ({
    user,
    consultations,
    setConsultationsListAsync,
    removeConsultationAsync,
    setConsultationsList,
}) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [showConfig, setShowConfig] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [event, selectEvent] = useState(null);


    const handleClickEvent = (ev) => {
        if (ev.patient) {
            setOpenPreview(true);
            selectEvent(ev);
        } else {
            confirm({
                title: TEXTS.deleteAvailabilityTitle[lang],
                icon: <ExclamationCircleOutlined />,
                content: TEXTS.deleteAvailabilityContent[lang],
                okText: TEXTS.deleteAvailabilityOkText[lang],
                okType: 'danger',
                onOk: () => {
                    removeConsultationAsync(ev._id);
                    message.success(TEXTS.deleteAvailabilitySuccessMsg[lang]);
                },
                cancelText: TEXTS.deleteAvailabilityCancelText[lang],
            });
        }
    };

    useEffect(() => {
        setConsultationsListAsync();
    }, [user]);

    useEffect(
        () => () => {
            setConsultationsList([]);
        },
        [],
    );


    if (showConfig) return <AgendaConfig handleGoBack={() => setShowConfig(false)} />;

    return (
        <div id="agenda">

            <div className="section-content-container">
                <Card>
                    <div
                        className="flex flex-wrap justify-c-sb m-bottom-medium"
                    >
                        <div className="flex">

                            <Button
                                className="m-right-medium"
                                onClick={() => setShowConfig(!showConfig)}
                                type="primary"
                                icon={<SettingOutlined />}
                            >
                                Configurer votre agenda
                            </Button>
                            <Button
                                onClick={() => setOpenAdd(!openAdd)}
                                type="primary"
                                icon={<PlusCircleOutlined />}
                            >
                                {TEXTS.addButton[lang]}
                            </Button>

                        </div>

                        <div className="flex-column" style={{ marginTop: 22 }}>
                            <Badge
                                className="badge"
                                status="error"
                                text={TEXTS.booked[lang]}
                            />
                            <Badge
                                className="badge"
                                status="success"
                                text={TEXTS.available[lang]}
                            />
                        </div>
                    </div>
                    <Calendar
                        events={consultations}
                        handleClickEvent={handleClickEvent}
                    />
                </Card>
                <AddModal open={openAdd} handleClose={() => setOpenAdd(false)} />

                {event ? (
                    <PreviewEvent
                        event={event}
                        handleClose={() => {
                            setOpenPreview(false);
                            selectEvent(null);
                        }}
                        open={openPreview}
                    />
                ) : null}
            </div>
        </div>
    );
};

Agenda.propTypes = {
    consultations: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    setConsultationsListAsync: PropTypes.func.isRequired,
    removeConsultationAsync: PropTypes.func.isRequired,
    setConsultationsList: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    consultations: state.consultations.consultationsList,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setConsultationsListAsync,
        removeConsultationAsync,
        setConsultationsList,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Agenda);
