/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Modal, Typography, Tag } from 'antd';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';
import { loadProfileImage } from '../../api/images';
import { getUserInitials, getFullName, formatDateForTelec } from '../../helpers/functions';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/previewEvent.texts';

const lang = getLang();
const { Title, Paragraph } = Typography;

const PreviewEvent = ({ open, handleClose, event }) => (
    <Modal
        footer={null}
        onCancel={handleClose}
        visible={open}>
        <div className="flex" style={{ alignItems: 'center', marginBottom: 15 }}>
            <Avatar
                src={loadProfileImage(event.patient._id, 'preview')}
                initials={getUserInitials(event.patient)}
                size={40} />
            <Title
                level={4}
                style={{ marginLeft: 10, marginTop: 5 }}>
                {getFullName(event.patient)}
            </Title>
        </div>
        <div className="flex-column" style={{ padding: '0 15px' }}>
            <div className="flex">
                <Paragraph strong> Date: &nbsp;</Paragraph>
                <Paragraph code>
                    {formatDateForTelec(event.date)}
                </Paragraph>
            </div>
            <div className="flex">
                <Paragraph strong>
                    {TEXTS.cPurpose[lang]}
                </Paragraph>
                <div className="flex flex-wrap">
                    {event.cPurposes.map(c => (
                        <Tag key={c} style={{ margin: 5 }} color="processing">{c}</Tag>
                    ))}
                </div>
            </div>

        </div>
    </Modal>
);


PreviewEvent.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
};


export default PreviewEvent;
