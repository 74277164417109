import localeFr from 'antd/es/date-picker/locale/fr_FR';
import localeUs from 'antd/es/date-picker/locale/en_US';
import commonTexts from '../../../config/common.texts';

const { inputErrorRequired } = commonTexts;

export default {
    inputErrorRequired,
    university: {
        fr: 'Université',
        en: 'University',
    },
    diploma: {
        fr: 'Intitulé du diplome',
        en: 'Diploma title',
    },
    year: {
        fr: 'Année',
        en: 'Year',
    },
    yearLimit: {
        fr: `Doit être diplomé entre 1920 et ${new Date().getFullYear()}`,
        en: `Value must be between 1920 and ${new Date().getFullYear()}`,
    },
    deleteButton: {
        fr: 'Supprimer',
        en: 'Delete',
    },
    addButton: {
        fr: 'Ajouter',
        en: 'Add',
    },
    startRange: {
        fr: 'Date de début',
        en: 'Start date',
    },
    endRange: {
        fr: 'Date de fin',
        en: 'End date',
    },
    datePickerLocales: {
        fr: localeFr,
        en: localeUs,
    },
    addedSuccessfully: {
        fr: 'Cursus ajouté avec succès.',
        en: 'University degree added successfully.',
    },
    deletedSuccessfully: {
        fr: 'Cursus supprimé avec succès.',
        en: 'University degree deleted successfully.',
    },
};
