import React from 'react';
import { connect } from 'react-redux';
import {
    Typography, Divider, Statistic, Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getFullName } from '../../helpers/functions';
import ProfilePicture from '../ProfilePicture';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import './styles.css';

const lang = getLang();
const { Title, Paragraph } = Typography;

const RatingTitle = () => (
    <Tooltip title={TEXTS.tooltip[lang]}>
        Revenu
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
    </Tooltip>
);

const UserCard = ({
    showStat, user, showUpload, telecNumber,
}) => (
    <div className="flex flex-wrap user-card-container">
        <div className="flex flex-wrap">
            <ProfilePicture showUpload={showUpload} />

            <div
                className="flex-column user-card-identity"
            >
                <Title level={4}>{`Dr. ${getFullName(user)}`}</Title>
                <Paragraph style={{ marginLeft: 8 }}>
                    {user.speciality}
                </Paragraph>
            </div>
        </div>
        {showStat ? (

            <div className="flex flex-wrap user-card-statistics">
                <Statistic
                    style={{ margin: '0 1rem' }}
                    title={<RatingTitle />}
                    value={user.pricing * telecNumber}
                    suffix="€"
                />
                <Divider type="vertical" className="user-card-divider" />
            </div>
        ) : null}
    </div>
);


UserCard.propTypes = {
    user: PropTypes.object.isRequired,
    showStat: PropTypes.bool,
    showUpload: PropTypes.bool,
    telecNumber: PropTypes.number,
};

UserCard.defaultProps = {
    showStat: true,
    showUpload: true,
    telecNumber: 0,
};

const stateToProps = state => ({
    user: state.auth.user,
});


export default connect(stateToProps)(UserCard);

/*
 <Statistic style={{ margin: '0 1rem' }} title={TEXTS.country[lang]} value={user.country} />
*/
