/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card,
    Steps,
    Typography,
    Button,
    InputNumber,
    Result,
    message,
    Spin,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { updateUserAsync } from '../../redux/actions/auth';
import { navigateTo } from '../../history';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import './styles.css';
import { STRIPE_CLIENT_ID } from '../../config';

const lang = getLang();
const { Step } = Steps;
const { Paragraph } = Typography;


const prepaeStripeExpressURL = (user) => {
    const scope = 'read_write';
    const responseType = 'code';
    const prefilledURL = 'https://aerohealth.ai';

    return `https://connect.stripe.com/express/oauth/authorize?client_id=${STRIPE_CLIENT_ID}&state=${user._id}&scope=${scope}&response_type=${responseType}&stripe_user[email]=${user.email}&stripe_user[url]=${prefilledURL}`;
};

class InitialConfig extends React.Component {
  state = {
      activeStep: 0,
      pricing: null,
  };

  componentDidMount() {
      this.prepareStep();
  }

  componentDidUpdate(props) {
      const { user } = this.props;
      if (props.user !== user) {
          this.prepareStep();
      }
  }

  setPricing = (pricing) => {
      this.setState({ pricing });
  };


  setActiveStep = (activeStep) => {
      this.setState({ activeStep });
  };

  handleNext = () => {
      const { activeStep, pricing } = this.state;
      const { updateUserAsync } = this.props;
      switch (activeStep) {
          case 2:
              if (pricing) {
                  updateUserAsync({ pricing });
              } else {
                  message.error(TEXTS.step2PricingError[lang]);
              }
              break;

          default:
              break;
      }
  };

  prepareStep = () => {
      const { user } = this.props;
      if (!user.stripeAccount) return this.setActiveStep(0);
      if (!user.pricing) return this.setActiveStep(2);
      return this.setActiveStep(3);
  };

  showStep = () => {
      const { activeStep } = this.state;
      const { user } = this.props;
      switch (activeStep) {
          case 0:
              return (
                  <Result
                      icon={<SmileOutlined />}
                      style={{ paddingBottom: 12 }}
                      title={TEXTS.step0ResultTitle[lang]}
                      status="success"
                      subTitle={TEXTS.step0ResultSubTitle[lang]}
                      extra={(
                          <Button
                              size="large"
                              onClick={() => {
                                  this.setActiveStep(1);
                              }}
                              type="primary"
                          >
                              {TEXTS.step0StartButton[lang]}
                          </Button>
                      )}
                  />
              );
          case 1:
              if (user.stripeAccount == null) {
                  return (
                      <div id="initial-config-stripe">
                          <Paragraph>
                              <Paragraph>{TEXTS.step1Description1[lang]}</Paragraph>
                              <Paragraph>{TEXTS.step1Description2[lang]}</Paragraph>
                          </Paragraph>
                          <Button
                              style={{ marginTop: '30' }}
                              size="large"
                              type="primary"
                              className="center-button"
                          >
                              {/* TODO ADD CSRF ATTACKS SECURITY */}
                              <a
                                  href={prepaeStripeExpressURL(user)}
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                  {TEXTS.step1CTA[lang]}
                              </a>
                          </Button>

                      </div>
                  );
              }
              return (
                  <div id="initial-config-stripe">
                      <Paragraph>
                          <Paragraph>{TEXTS.AlreadyStripeAccount[lang]}</Paragraph>
                      </Paragraph>
                      <Button
                          style={{ marginTop: 30 }}
                          size="large"
                          type="primary"
                          className="center-button"
                          onClick={() => this.setActiveStep(2)
                          }
                      >
                          Suivant
                      </Button>

                  </div>

              );

          case 2:
              return (
                  <div className="flex-column">
                      <div className="flex flex-wrap" style={{ alignItems: 'baseline' }}>
                          <Paragraph>{TEXTS.step2InputLabel[lang]}</Paragraph>
                          <InputNumber
                              onChange={val => this.setPricing(val)}
                              formatter={value => `€ ${value}`}
                              style={{ marginLeft: '1rem', width: 'max-content' }}
                              min={1}
                              max={500}
                              placeholder={TEXTS.step2InputPlaceholder[lang]}
                          />
                      </div>

                      <Button
                          onClick={this.handleNext}
                          style={{ width: 'min-content' }}
                          className="center-button"
                          type="primary"
                          size="large"
                      >
                          {TEXTS.nextButton[lang]}
                      </Button>
                  </div>
              );
          case 3:
              return (
                  <Result
                      status="success"
                      title={TEXTS.lastResultTitle[lang]}
                      subTitle={TEXTS.lastResultSubTitle[lang]}
                      extra={[

                          <Button
                              onClick={() => navigateTo('/profile')}
                              type="primary"
                              key="profile"
                          >
                              {TEXTS.lastResultProfileButton[lang]}
                          </Button>,
                      ]}
                  />
              );
          default:
              return <div />;
      }
  };

  render() {
      const { activeStep } = this.state;
      const { contentIsLoading } = this.props;
      return (
          <Card className="initial-config-card">
              <Spin spinning={contentIsLoading} size="large">
                  <Steps size="small" current={activeStep}>
                      <Step title={TEXTS.step0[lang]} />
                      <Step title={TEXTS.step1[lang]} />
                      <Step title={TEXTS.step2[lang]} />
                      {/* <Step title={TEXTS.step3[lang]} /> */}
                  </Steps>
                  <div className="initial-config-steps-container">
                      {this.showStep()}
                  </div>
              </Spin>
          </Card>
      );
  }
}

InitialConfig.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(InitialConfig);
