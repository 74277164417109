/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Layout,
    Menu,
    Divider,
} from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import { isConfigComplete } from '../../helpers/functions';
import items, { subMenuConfig } from './config';
import { navigateTo } from '../../history';
import useWindowSize from '../../hooks/useWindowSize';
import './styles.css';

const { SubMenu, Item } = Menu;
const { Sider } = Layout;

const CustomMenu = props => (
    <div className="sidebar">
        <SubMenu
            {...props}
            disabled={props.disableItems}
            className="sidebar-menu-item p-left-medium"
            key={subMenuConfig.route}
            icon={subMenuConfig.icon({ fontSize: 24 })}
            title={<span className="submenu-title">{subMenuConfig.title}</span>}
            style={{
                fontSize: 16,
                paddingLeft: '0px !important',
                margin: '0 10px',
            }}
        >
            {Object.values(subMenuConfig.items).map(subItem => (
                <Item
                    {...props}
                    disabled={props.disableItems}
                    className="sidebar-menu-item flex justify-c-start p-left-medium"
                    key={subItem.route}
                    icon={subItem.icon({ fontSize: 20 })}
                    onClick={() => {
                        navigateTo(subItem.route);
                    }}
                    style={{ fontSize: 14, paddingLeft: '0px !important' }}
                >
                    {subItem.title}
                </Item>
            ))}
        </SubMenu>
        <Divider />
    </div>
);

CustomMenu.propTypes = {
    disableItems: PropTypes.bool.isRequired,
    selectKey: PropTypes.func.isRequired,
};

const Sidebar = ({ user, currentRoute, ...rest }) => {
    const size = useWindowSize();

    const [isPortrait, setIsPortrait] = React.useState(size.width < size.height);
    const [isCollapsed, setIsCollapsed] = React.useState(
        isPortrait,
    );

    React.useEffect(() => {
        if (size.width < size.height) {
            setIsPortrait(true);
            setIsCollapsed(true);
        } else {
            setIsPortrait(false);
            setIsCollapsed(false);
        }
    }, [size]);

    const handleCollapse = value => setIsCollapsed(value);

    const disableItems = !isConfigComplete(user) || !user.email_verified;
    return (
        <>
            {isPortrait ? (
                <div className="sidebar-collapse-trigger">
                    {React.createElement(
                        isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: 'trigger',
                            onClick: () => setIsCollapsed(!isCollapsed),
                        },
                    )}
                </div>
            ) : null}
            <Sider
                collapsible={isPortrait}
                collapsed={isCollapsed}
                onCollapse={handleCollapse}
                trigger={null}
                width={280}
                className={`sidebar${isPortrait ? ' collapsible' : ''}`}
            >
                <div className="p-medium m-bottom-medium">
                    <img
                        src="ah-logo.svg"
                        alt="logo"
                        className="full-width center-button"
                    />
                </div>
                <Menu
                    className="sidebar"
                    mode="inline"
                    selectedKeys={[currentRoute]}
                    {...rest}
                >
                    {Object.values(items).map(
                        item => item.name !== 'config' && (
                        <>
                            <Item
                                disabled={disableItems}
                                className="sidebar-menu-item flex justify-c-start p-left-medium"
                                key={item.route}
                                icon={item.icon({ fontSize: 24 })}
                                onClick={() => {
                                    navigateTo(item.route);
                                }}
                                style={{ fontSize: 16, paddingLeft: 0 }}
                            >
                                {item.title}
                            </Item>
                            <Divider />
                        </>
                        ),
                    )}
                    <CustomMenu disableItems={disableItems} />
                </Menu>
            </Sider>
        </>
    );
};

Sidebar.propTypes = {
    currentRoute: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps)(Sidebar);
