/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Button, Collapse, message, Radio,
} from 'antd';
import {
    FileOutlined,
    CloudDownloadOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { connect, useDispatch } from 'react-redux';
import PropTypes, { object } from 'prop-types';
import { addDocuments, loadDocument, addListDocuments } from '../../api/documents';
import Upload from './upload';
import './styles.css';
import PatientsList from './patientsList';
import { isUndefined } from 'util';
import TEXTS from './_texts/upload.texts';
import { checkAuthAsync, updatePatient } from '../../redux/actions/auth';

import { getLang } from '../../config/helpers';
import UploadModal from './uploadModal';

const lang = getLang();
const { Panel } = Collapse;
// TODO Refactor document categories
const parseDocuments = (filename, userId, categorie, _id) => ({
    filename,
    filepath: loadDocument(userId, 'documents', filename),
    categorie,
    _id,
});

const Documents = ({ user }) => {
    const dispatch = useDispatch();
    const [selectedPatient, selectPatient] = useState();
    const [parsedDocuments, setParsedDocuments] = useState([]);
    const [selectedFileIndex, setSelectedFileIndex] = useState();
    const [listCategorieByPatient, setListCategorieByPatient] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);

    const onPatientSelect = (patient) => {
        selectPatient(patient);
        setParsedDocuments(patient.documents);
    };

    useEffect(() => {
        if (user) {
            const rawDocs = selectedPatient ? selectedPatient.documents : [];
            const docs = [];
            rawDocs.map((doc) => {
                const { categorie, document, _id } = doc;
                if (document == undefined) {
                    const newDoc = Object.values(doc).join('');
                    docs.push(
                        parseDocuments(newDoc, selectedPatient._id, 'Pas de categorie', _id),
                    );
                } else {
                    docs.push(
                        parseDocuments(document, selectedPatient._id, categorie, _id),
                    );
                }
            });
            setParsedDocuments(docs);
        }
    }, [selectedPatient]);

    useEffect(() => {
        const result = [
            ...parsedDocuments
                .reduce((r, {
                    categorie, _id, filename, filepath,
                }) => {
                    r.has(categorie)
            || r.set(categorie, {
                categorie,
                parsedDocuments: [],
            });

                    r.get(categorie).parsedDocuments.push({ _id, filename, filepath });

                    return r;
                }, new Map())
                .values(),
        ];
        setListCategorieByPatient(result);
    }, [parsedDocuments]);

    const onCreate = async (values) => {
        try {
            const { data } = await addListDocuments(selectedPatient._id, 'documents', values);
            dispatch(updatePatient(data));
            setDisplayModal(false);
            // addDocumentAsync(data.data.documents);
            message.success(TEXTS.uploadSuccessMSG[lang]);
        } catch (err) {
            console.log(err);
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };
    return (
        <>
            <Button
                type="primary"
                size="middle"
                onClick={() => setDisplayModal(true)}
                style={{ width: '20%', marginLeft: '40px' }}
            >
                Ajouter un document
            </Button>
            <UploadModal
                visible={displayModal}
                onCreate={onCreate}
                onCancel={() => {
                    setDisplayModal(false);
                }}
            />
            <div className="flex documents-container">
                <div
                    style={{
                        marginTop: 10,
                        marginRight: 24,
                        marginLeft: 40,
                        width: '65%',
                    }}
                >
                    <Collapse defaultActiveKey={[]}>
                        {listCategorieByPatient
                            ? listCategorieByPatient.map((grouped, index) => (
                                <Panel
                                    header={
                                        grouped.categorie ? grouped.categorie : 'Pas de categorie'
                                    }
                                    key={index}
                                >
                                    <Radio.Group
                                        onChange={({ target }) => {
                                            setSelectedFileIndex(target.value);
                                        }}
                                        style={{
                                            width: '100% !important',
                                            display: 'flex !important',
                                            flexDirection: 'row !important',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            {grouped.parsedDocuments.map((doc, docIndex) => {
                                                const isSelected = selectedFileIndex != null
                                                    ? doc._id === selectedFileIndex._id
                                                    : null;
                                                const Icon = isSelected
                                                    ? CloudDownloadOutlined
                                                    : FileOutlined;
                                                return (
                                                    <Radio.Button
                                                        value={doc}
                                                        className="doc-file-radio"
                                                    >
                                                        <p>{doc.filename}</p>
                                                        <Icon
                                                            style={{
                                                                width: '100%',
                                                                fontSize: '4em',
                                                                marginBottom: '0.2em',
                                                            }}
                                                            onClick={(e) => {
                                                                if (isSelected) {
                                                                    e.preventDefault();
                                                                    console.log(
                                                                        'parsedDocuments[index].filepath[docIndex]',
                                                                        doc.filepath,
                                                                    );
                                                                    window.open(doc.filepath);
                                                                }
                                                            }}
                                                        />
                                                        {doc.document}
                                                    </Radio.Button>
                                                );
                                            })}
                                        </div>
                                    </Radio.Group>
                                </Panel>
                            ))
                            : ''}
                    </Collapse>
                    {/* <Upload fileType="document" /> */}
                </div>

                <PatientsList
                    onPatientSelect={onPatientSelect}
                    selectedPatient={selectedPatient}
                />
            </div>
        </>
    );
};

Documents.propTypes = {
    user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps)(Documents);
