/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    List, Card, Button, Typography, Tooltip, Input,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';
import { setPatientsAsync } from '../../redux/actions/auth';
import Avatar from '../../components/Avatar';
import { getFullName, getUserInitials } from '../../helpers/functions';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import './styles.css';

const lang = getLang();
const { Title } = Typography;

const PatientsList = ({
    user,
    setPatientsAsync,
    patients,
    onPatientSelect,
    selectedPatient,
    style,
}) => {
    const [strFilter, setStrFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fuseOptions = {
        includeScore: true,
        keys: ['firstName', 'lastName'],
    };
    const fuse = new Fuse(patients, fuseOptions);

    const handleListFiltering = () => {
        const strFilteredResults = strFilter.trim().length === 0
            ? patients
            : fuse
                .search(strFilter)
                .filter(result => result.score < 0.2)
                .map(result => result.item);

        setFilteredData(strFilteredResults);
    };

    useEffect(() => {
        if (user) {
            setPatientsAsync();
        }
    }, [user]);

    useEffect(() => {
        if (patients.length && !selectedPatient) {
            onPatientSelect(patients[0]);
        } else if (selectedPatient) {
            for (const patient of patients) {
                if (patient._id === selectedPatient._id) {
                    onPatientSelect(patient);
                    break;
                }
            }
        }
    }, [patients]);

    useEffect(() => {
        handleListFiltering();
    }, [strFilter, patients]);


    return (
        <Card className="authorized-doctors-card" style={style}>
            <Title level={4}>
                <Tooltip title={TEXTS.headerToolip[lang]}>
                    {TEXTS.header[lang]}
                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
                <hr style={{ color: 'rgba(0, 0, 0, 0.2)' }} />
            </Title>
            <Input
                autoFocus
                size="large"
                name="search-input"
                placeholder="Filtrer..."
                // eslint-disable-next-line no-console
                onChange={e => setStrFilter(e.target.value)}
                style={{ marginBottom: '0.5em' }}
            />
            <List
                size="large"
                itemLayout="horizontal"
                dataSource={filteredData}
                renderItem={patient => (
                    <Card
                        hoverable
                        className={classnames(
                            {
                                'selected-patient':
                  selectedPatient && selectedPatient._id === patient._id,
                            },
                            'patient-card',
                        )}
                        onClick={() => onPatientSelect(patient)}
                    >
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar initials={getUserInitials(patient)} />}
                                title={<Button type="link">{getFullName(patient)}</Button>}
                            />
                        </List.Item>
                    </Card>
                )}
            />
        </Card>
    );
};

PatientsList.propTypes = {
    user: PropTypes.object.isRequired,
    setPatientsAsync: PropTypes.func.isRequired,
    patients: PropTypes.array.isRequired,
    onPatientSelect: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
    style: PropTypes.object,
};

PatientsList.defaultProps = {
    selectedPatient: null,
    style: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    patients: state.auth.patients,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setPatientsAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(PatientsList);
