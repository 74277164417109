import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import allReducers from './reducers';

export default createStore(
    combineReducers({
        ...allReducers,
    }),
    composeWithDevTools(applyMiddleware(thunk)),
);
