/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import {
    Button, message, Typography, Upload,
} from 'antd';
import ImageGallery from 'react-image-gallery';
import { RedoOutlined, UndoOutlined, UploadOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addImages, loadImage } from '../../api/images';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import 'react-image-gallery/styles/css/image-gallery.css';
import PatientsList from './patientsList';
import './styles.css';
import { checkAuthAsync, setPatientsAsync } from '../../redux/actions/auth';


// TODO Refactor image categories
const parseImage = (filename, userId) => ({
    original: loadImage(userId, 'photos', 'lossless', filename),
});

const { Paragraph } = Typography;
const lang = getLang();

const ImagesSection = ({ setPatientsAsync, patients }) => {
    const [parsedImages, setParsedImages] = useState([]);
    const [selectedPatient, selectPatient] = useState();
    const [rotationDeg, setRotationDeg] = useState(0);
    const imageGalleryRef = useRef();

    const onPatientSelect = (patient) => {
        selectPatient(patient);
        console.log('[debug] ----', patient);
        const imgs = [];
        patient.photos.map(img => imgs.push(parseImage(img, patient._id)));
        setParsedImages(imgs);
    };

    const openImage = (e) => {
        const imageWindow = window.open('', '_blank');
        imageWindow.document.write(`
      <html>
        <head>
        </head>
        <body>
          <img src="${e.target.src}" alt="Example">
        </body>
      </html>
      `);
        return imageWindow;
    };

    const uploadImage = async (file) => {
        try {
            await addImages(selectedPatient._id, 'photos', file);
            checkAuthAsync();
            message.success(TEXTS.uploadSuccessMSG[lang]);
            await setPatientsAsync();
        } catch (err) {
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    return (
        <>
            {selectedPatient && (
                <Upload
                    showUploadList={false}
                    customRequest={({ file }) => {
                        uploadImage(file);
                    }}
                    listType="text"
                >
                    <Button
                        block
                        type="primary"
                        size="large"
                        style={{ marginTop: 10, marginLeft: 20 }}
                    >
                        <UploadOutlined style={{ marginRight: 5 }} />
                        {TEXTS.uploadButton[lang]}
                    </Button>
                </Upload>
            )}
            <div className="patient-images-container">
                <div style={{ maxWidth: '80vw', margin: '24px 20px 0' }}>
                    {parsedImages.length ? (
                        <div className="flex rotate-buttons">
                            <Button
                                onClick={() => {
                                    setRotationDeg(rotationDeg > 0 ? rotationDeg - 90 : 270);
                                }}
                                size="large"
                                shape="circle"
                                type="primary"
                                icon={<UndoOutlined />}
                            />
                            <Button
                                onClick={() => {
                                    setRotationDeg(rotationDeg < 270 ? rotationDeg + 90 : 0);
                                }}
                                size="large"
                                shape="circle"
                                type="primary"
                                icon={<RedoOutlined />}
                            />
                        </div>
                    ) : null}
                    {selectedPatient && !parsedImages.length ? (
                        <Paragraph>{TEXTS.emptyImages[lang]}</Paragraph>
                    ) : (
                        <ImageGallery
                            ref={imageGalleryRef}
                            items={parsedImages}
                            showPlayButton={false}
                            onClick={e => openImage(e)}
                            showBullets
                            showFullscreenButton={parsedImages.length > 0}
                            additionalClass={`rotate-${rotationDeg}`}
                        />
                    )}
                </div>
                <PatientsList
                    onPatientSelect={onPatientSelect}
                    selectedPatient={selectedPatient}
                />
            </div>
        </>
    );
};

ImagesSection.propTypes = {
    setPatientsAsync: PropTypes.func.isRequired,
    patients: PropTypes.array.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    patients: state.auth.patients,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setPatientsAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ImagesSection);
