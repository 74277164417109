import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from './history';
import Auth from './sections/Auth';
import InitialConfig from './sections/InitialConfig';
import EmailVerification from './sections/EmailVerification';
import Dashboard from './sections/Dashboard';
import Profile from './sections/Profile';
import Agenda from './sections/Agenda';
import IncomingTelecs from './sections/Teleconsultations/Incoming';
import TelecHistory from './sections/Teleconsultations/History';
import PatientImages from './sections/PatientImages';
import Documents from './sections/Documents';
import CPurpose from './sections/cPurpose';
import Calendar from './sections/calendar';

const Routes = () => (
    <Router history={history}>
        <Switch>
            <Route path="/config" component={InitialConfig} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/profile" component={Profile} />
            <Route path="/agenda" component={Calendar} />
            <Route path="/t-incoming" component={IncomingTelecs} />
            <Route path="/t-history" component={TelecHistory} />
            <Route path="/images" component={PatientImages} />
            <Route path="/documents" component={Documents} />
            <Route path="/verif-email" component={EmailVerification} />
            <Route path="/motif" component={CPurpose} />
        </Switch>
    </Router>
);

export const AuthRoutes = () => (
    <Router history={history}>
        <Switch>
            <Route path="/auth" component={Auth} />
        </Switch>
    </Router>
);

export default Routes;
