/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import { notification } from 'antd';
import { db } from '../../../firebase';
import { getLang } from '../../../config/helpers';
import TEXTS from './_texts/index.texts';

const lang = getLang();

const notificationsListner = (uid, cb) => async () => {
    try {
        const docRef = db.collection('doctors').doc(uid);
        const unsubscriber = await docRef.onSnapshot((doc) => {
            // eslint-disable-next-line max-len
            // TODO check the current route and refresh the data. (add cb to be executed in params for instance)
            if (doc.data()) {
                if (doc.data().bookings) {
                    // eslint-disable-next-line array-callback-return
                    doc.data().bookings.map((ev) => {
                        const eventInfo = ev.split('-');
                        notification.info({
                            message: TEXTS.bookedTitle[lang],
                            description: TEXTS.bookedDescripton[lang](eventInfo),
                            duration: 20000,
                        });
                        setTimeout(() => {}, 5000);
                    });
                }

                if (doc.data().cancellations) {
                    // eslint-disable-next-line array-callback-return
                    doc.data().cancellations.map((ev) => {
                        const eventInfo = ev.split('-');
                        notification.warning({
                            message: TEXTS.cancelledTitle[lang],
                            description: TEXTS.cancelledDescripton[lang](eventInfo),
                            duration: 20000,
                        });
                        setTimeout(() => {}, 5000);
                    });
                }

                docRef.set({ bookings: [], cancellations: [] });

                cb();
            }
        });
        return unsubscriber;
    } catch (err) {
    // null is not a pointer of undefined
    }
};

const telecPaymentListener = async (uid, errorCb) => {
    try {
        const docRef = db.collection('doctors').doc(uid);
        const unsubscriber = await docRef.onSnapshot((doc) => {
            if (doc.data()) {
                if (doc.data().paymentError) {
                    docRef.set({ paymentError: false });
                    errorCb();
                }
            }
        });
        return unsubscriber;
    } catch (err) {
    // null is not a pointer of undefined
    }
};

export { notificationsListner, telecPaymentListener };
