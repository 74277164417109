import en from 'antd/es/locale/en_US';
import fr from 'antd/es/locale/fr_FR';
import commonTexts from '../../../config/common.texts';

const { inputErrorRequired } = commonTexts;


export default {
    inputErrorRequired,
    title: {
        fr: 'Ajouter un créneau de téléconsultation',
        en: 'Add a teleconsultation time slot',
    },
    multiple: {
        fr: 'Une plage horaire (pour une même journée)',
        en: 'Multiple time slots (for the same day)',
    },
    single: {
        fr: 'Un créneau unique',
        en: 'A unique time slot',
    },
    workingInterval: {
        fr: 'Plage horaire',
        en: 'Work time interval',
    },
    telecDuration: {
        fr: 'Durée d’une téléconsultation (en mn)',
        en: 'Teleconsultation duration (in minutes)',
    },
    submitButton: {
        fr: 'Valider',
        en: 'Validate',
    },
    startTime: {
        fr: 'Horaire de début',
        en: 'Start time',
    },
    configLocale: {
        fr,
        en,
    },
    addSequenceError: {
        fr: 'Valeurs incompatibles.',
        en: 'Incompatible values.',
    },
    multipleAddSuccess: {
        fr: 'Plage horaire ajoutée avec succès.',
        en: 'Time slots added successfully.',
    },
    singleAddSuccess: {
        fr: 'Votre créneau de téléconsultation a été ajouté avec succès.',
        en: 'Time slot added successfully.',
    },
};
