/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form,
    Switch,
    message,
    ConfigProvider,
    Descriptions,
    Button,
    Card, Breadcrumb,
    InputNumber,
} from 'antd';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { createManyAsync } from '../../redux/actions/consultations';
import { getLang } from '../../config/helpers';
import CustomRangePicker from '../../components/CustomRangePicker';
import CustomTimePicker from '../../components/CustomTimePicker';
import TEXTS from './_texts/add.texts';
import FORM_RULES from '../../helpers/formRules';
import 'moment/locale/fr';
import './styles.css';
import { setUser } from '../../redux/actions/auth';


const lang = getLang();
moment.locale(lang);

const weekDays = [
    { label: 'Lundi', value: 'monday' },
    { label: 'Mardi', value: 'tuesday' },
    { label: 'Mercredi', value: 'wednesday' },
    { label: 'Jeudi', value: 'thursday' },
    { label: 'Vendredi', value: 'friday' },
    { label: 'Samedi', value: 'saturday' },
    { label: 'Dimanche', value: 'sunday' },
];

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i += 1) {
        result.push(i);
    }
    return result;
}
const disabledMinutes = () => range(0, 10);

const AgendaConfigModal = ({
    createManyAsync, user, handleGoBack, setUser,
}) => {
    const [form] = Form.useForm();
    const [change, triggerChange] = useState(false);
    const [rangeValues, setRangeValues] = useState({});
    const doctorPricing = user.pricing;

    const getDurationValue = day => moment().minute(user.agendaConfig[day].unitDuration);

    const getRangeValue = (day) => {
        if (user.agendaConfig && user.agendaConfig[day] && user.agendaConfig[day].momentVals) {
            return user.agendaConfig[day].momentVals.map(i => moment(i));
        }
        if (rangeValues[day] && rangeValues[day].momentVals) {
            return rangeValues[day].momentVals;
        }
        return ['', ''];
    };


    useEffect(() => {
        if (user.agendaConfig) {
            const keys = Object.keys(user.agendaConfig);
            const initialRangeValues = {};

            keys.forEach((day) => {
                if (user.agendaConfig[day].value) {
                    form.setFieldsValue({
                        [day]: {
                            ...user.agendaConfig[day],
                            unitDuration: getDurationValue(day),
                            momentVals: getRangeValue(day),
                        },
                    });

                    initialRangeValues[day] = {
                        ...user.agendaConfig[day],
                        unitDuration: user.agendaConfig[day].unitDuration,
                        momentVals: getRangeValue(day),
                    };
                }
            });

            triggerChange(!change);

            setRangeValues(initialRangeValues);
        }
    }, []);

    const onRangeError = (day) => {
        form.validateFields([`${day}-timeRange`]);
    };

    const handleAddSequence = () => {
        const noErrors = true;
        let i = 0;
        const keys = Object.keys(rangeValues);
        const body = { };

        while (noErrors && i < keys.length) {
            const {
                unitDuration, momentVals, timeRange, pricing,
            } = rangeValues[keys[i]];
            body[keys[i]] = {
                timeRange,
                unitDuration,
                momentVals,
                pricing,
                value: true,
            };
            i++;
        }
        const formValues = form.getFieldsValue();

        const formKeys = Object.keys(formValues);
        formKeys.forEach((day) => {
            if (formValues[day].value && !formValues.momentVals) {
                const currentConfig = user.agendaConfig ? user.agendaConfig[day] : {};

                formValues[day] = {
                    ...currentConfig,
                };
            }
        });
        const agendaConfig = { ...formValues, ...body };

        createManyAsync({ agendaConfig, isAgendaConfig: true, doctorPricing });
        message.success('Configuration mise à jour avec succès');
        setUser({ ...user, agendaConfig });
        handleGoBack();
    };


    return (
        <div className="section-content-container">

            <Card>
                <Breadcrumb className="m-bottom-medium">
                    <Breadcrumb.Item link>
                        <Button onClick={handleGoBack} type="link" style={{ padding: 0 }}>
                            Agenda
                        </Button>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Configuration
                    </Breadcrumb.Item>
                </Breadcrumb>
                <ConfigProvider locale={TEXTS.configLocale[lang]}>
                    <Form
                        form={form}
                        layout="vertical"
                        onValuesChange={() => triggerChange(!change)}
                        onFinish={handleAddSequence}
                        className="agenda-config-form"
                        style={{ marginTop: 50 }}
                    >
                        {weekDays.map(w => (
                            <Descriptions
                                size="small"
                                className="agenda-config-description"
                                layout="vertical"
                            >
                                <Descriptions.Item style={{ width: 10 }}>
                                    <Form.Item
                                        key={w.value}
                                        className="flex"
                                        style={{
                                            flexDirection: 'row',
                                        }}
                                        name={[w.value, 'value']}
                                        label={w.label}
                                        layout="vertical"
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item style={{ width: 10 }}>
                                    <Form.Item
                                        name={[w.value, 'value']}
                                        noStyle>

                                        <Switch
                                            checked={form.getFieldValue([w.value, 'value'])}
                                            onChange={(value) => {
                                                form.setFieldsValue({ [w.value]: { value } });

                                                const rangeValuesClone = cloneDeep(rangeValues);
                                                delete rangeValuesClone[w.value];

                                                setRangeValues(rangeValuesClone);
                                            }}
                                        />
                                    </Form.Item>
                                </Descriptions.Item>


                                <Descriptions.Item style={{ width: 10 }}>
                                    {form.getFieldValue([w.value, 'value']) ? (
                                        <Form.Item
                                            rules={[FORM_RULES.required]}
                                            name={[w.value, 'momentVals']}
                                        >
                                            <CustomRangePicker
                                                name={w.value}
                                                onChange={(momentVals, val) => {
                                                    setRangeValues({
                                                        ...rangeValues,
                                                        [w.value]: {
                                                            ...rangeValues[w.value],
                                                            timeRange: val,
                                                            momentVals,
                                                        },
                                                    });
                                                }}
                                                format="HH:mm"
                                                value={getRangeValue(w.value)}
                                                onError={() => onRangeError(w.value)}
                                            />
                                        </Form.Item>
                                    ) : null}
                                    {form.getFieldValue([w.value, 'value']) ? (
                                        <Form.Item
                                            style={{ width: '70px', marginLeft: '30px' }}
                                            rules={[FORM_RULES.required]}
                                            name={[w.value, 'unitDuration']}
                                        >
                                            <CustomTimePicker
                                                onChange={(_, val) => {
                                                    setRangeValues({
                                                        ...rangeValues,
                                                        [w.value]: {
                                                            ...rangeValues[w.value],
                                                            unitDuration: val,
                                                        },
                                                    });
                                                }}
                                                format="mm"
                                                hideDisabledOptions
                                                minuteStep={5}
                                                disabledMinutes={disabledMinutes}
                                            />
                                        </Form.Item>
                                    ) : null}

                                    {form.getFieldValue([w.value, 'value']) ? (
                                        <Form.Item
                                            style={{ width: '70px', marginLeft: '30px' }}
                                            name={[w.value, 'pricing']}
                                            initialValue={user.pricing || 0}

                                        >
                                            <InputNumber placeholder="Tarif"
                                                onChange={(val) => {
                                                    setRangeValues({
                                                        ...rangeValues,
                                                        [w.value]: {
                                                            ...rangeValues[w.value],
                                                            pricing: val,
                                                        },
                                                    });
                                                }} />
                                        </Form.Item>
                                    ) : null}
                                </Descriptions.Item>


                            </Descriptions>
                        ))}
                        <Form.Item>
                            <div className="flex justify-c-center">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    size="medium"
                                >
                                    Confirmer
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </ConfigProvider>
            </Card>
        </div>
    );
};

AgendaConfigModal.propTypes = {
    createManyAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    handleGoBack: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        createManyAsync,
        setUser,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AgendaConfigModal);
