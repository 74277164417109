export default {
    breadCrumb1: {
        fr: 'Téléconsultations',
        en: 'Teleconsultations',
    },
    breadCrumb2: {
        fr: 'Historique',
        en: 'History',
    },
    title: {
        fr: 'Motifs de Consultations',
        en: 'Reasons for Consultations',
    },
    duration: {
        fr: 'Durée (minutes)',
        en: 'Duration (minutes)',
    },
    cPurpose: {
        fr: 'Motif de consultation',
        en: 'Reason for consultation',
    },
    securityNumber: {
        fr: 'Numéro de securité sociale',
        en: 'Security number',
    },
    emptyList: {
        fr: 'Vous n’avez effectué aucune motif.',
        en: "You don't have any accomplished reason.",
    },
    singleAddSuccess: {
        fr: 'Votre motif a été ajouté avec succès.',
        en: 'Consultation purpose added successfully.',
    },
    singleDeleteSuccess: {
        fr: 'Votre motif a été supprimé avec succès.',
        en: 'Consultation purpose deleted successfully.',
    },
};
