import { DateTime } from 'luxon';
import { getLang } from '../../config/helpers';
import TEXTS from './index.texts';

const lang = getLang();


export default {
    required: {
        required: true,
        message: TEXTS.inputErrorRequired[lang],
    },
    number: {
        validator: (_, value) => (!isNaN(value)
            ? Promise.resolve()
            : Promise.reject(
                TEXTS.inputErrorValidNumber[lang],
            )),
    },
    email: {
        type: 'email',
        message: TEXTS.inputErrorTypeEmail[lang],
    },
    time: {
        validator: (_, value) => ((value && !DateTime.fromFormat(value, 'HH:mm').invalid) || !value || value.trim().length === 0
            ? Promise.resolve()
        // eslint-disable-next-line prefer-promise-reject-errors
            : Promise.reject(new Error('Temps invalide'))),
    },
    validDate: {
        validator: (_, value) => ((value && !DateTime.fromFormat(value, 'dd/MM/yyyy').invalid) || !value || value.trim().length === 0
            ? Promise.resolve()
            : Promise.reject(new Error(TEXTS.inputErrorInvalidDate[lang]))),
    },
    phoneNumber: {
        validator: (_, value) => (!isNaN(value) && value.length === 8
            ? Promise.resolve()
            : Promise.reject(
                TEXTS.inputErrorValidNumber[lang],
            )),
    },

};
