export default {
    inputErrorTypeEmail: {
        fr: 'Veuillez insérer une adresse email valide.',
        en: 'Invalid e-mail address.',
    },
    inputErrorRequired: {
        fr: 'Ce champ est requis.',
        en: 'This field is required.',
    },
    inputErrorValidNumber: {
        fr: 'Veuillez indiquer un numéro de téléphone valide.',
        en: 'Please provide a valid phone number.',
    },
    inputErrorInvalidTime: {
        fr: 'Invalide.',
        en: 'Invalid time.',
    },
    inputErrorInvalidDate: {
        fr: 'Date invalide.',
        en: 'Invalid date.',
    },
    inputPasswordError: {
        fr: 'Veuillez saisir votre mot de passe.',
        en: 'This field is required.',
    },
};
