import axios from 'axios';
import { API_CONSULTATIONS, API_REQ_HEADER } from '../config';

const getAll = async () => {
    const res = await axios.get(`${API_CONSULTATIONS}/user`, API_REQ_HEADER);
    return res.data;
};

const getHistory = async () => {
    const res = await axios.get(`${API_CONSULTATIONS}/history`, API_REQ_HEADER);
    return res.data;
};

const getBooked = async () => {
    const res = await axios.get(`${API_CONSULTATIONS}/booked`, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const create = async (body) => {
    const res = await axios.post(API_CONSULTATIONS, body, API_REQ_HEADER);
    return res.data;
};

const createMany = async (body) => {
    const res = await axios.post(
        `${API_CONSULTATIONS}/many`,
        body,
        API_REQ_HEADER,
    );
    return res.data;
};

const remove = async (id) => {
    const res = await axios.delete(
        `${API_CONSULTATIONS}/d/${id}`,
        API_REQ_HEADER,
    );
    return res.data;
};

const removeBooked = async (id) => {
    const res = await axios.delete(`${API_CONSULTATIONS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const updateDateConsultation = async (body) => {
    const res = await axios.put(
        `${API_CONSULTATIONS}/${body.id}`,
        body,
        API_REQ_HEADER,
    );
    return res.data;
};

const billTeleconsultationById = consultationId => axios.put(
    `${API_CONSULTATIONS}/${consultationId}/bill`,
    {},
    API_REQ_HEADER,
);

const cancelBillingTeleconsultationById = (consultationId, thirdPartyPayer) => axios.put(
    `${API_CONSULTATIONS}/${consultationId}/cancel-bill`,
    { thirdPartyPayer },
    API_REQ_HEADER,
);

const refundTeleconsultationById = consultationId => axios.put(
    `${API_CONSULTATIONS}/${consultationId}/refund-bill`,
    {},
    API_REQ_HEADER,
);

export {
    getAll,
    getById,
    createMany,
    create,
    remove,
    getBooked,
    getHistory,
    removeBooked,
    updateDateConsultation,
    billTeleconsultationById,
    cancelBillingTeleconsultationById,
    refundTeleconsultationById,
};
