import React from 'react';
import { Result, Button } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPaymentError } from '../../redux/actions/consultations';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';

const lang = getLang();

const PaymentError = ({ setPaymentError }) => (
    <Result
        status="500"
        title={TEXTS.resultTitle[lang]}
        subTitle={TEXTS.resultSubtitle[lang]}
        extra={[
            <Button type="primary" onClick={() => { setPaymentError(false); }}>
                {TEXTS.extraButton[lang]}
            </Button>,
        ]}
    />
);

const dispatchToProps = dispatch => bindActionCreators(
    {
        setPaymentError,
    },
    dispatch,
);

PaymentError.propTypes = {
    setPaymentError: PropTypes.func.isRequired,
};

export default connect(null, dispatchToProps)(PaymentError);
