import axios from 'axios';
import { API_SUBSCRIPTION, API_REQ_HEADER } from '../config';

const invoicePreview = async () => {
    const res = await axios.get(`${API_SUBSCRIPTION}/invoice-preview`, API_REQ_HEADER);
    return res.data;
};


const getSubscriptions = async () => {
    const res = await axios.get(`${API_SUBSCRIPTION}/subscriptions`, API_REQ_HEADER);
    return res.data;
};

const refreshSubscription = async () => {
    const res = await axios.get(`${API_SUBSCRIPTION}/refresh`, API_REQ_HEADER);
    return res.data;
};

const create = async (body) => {
    const res = await axios.post(`${API_SUBSCRIPTION}/create-subscription`, body, API_REQ_HEADER);
    return res.data;
};

const cancelSubscription = async (credentials) => {
    const res = await axios.post(`${API_SUBSCRIPTION}/login`, credentials, API_REQ_HEADER);

    return res.data;
};


export {
    invoicePreview,
    create,
    cancelSubscription,
    getSubscriptions,
    refreshSubscription,
};
