export default {
    stepperTitle: {
        fr: 'Mon profil ',
        en: 'My profile ',
    },
    step0: {
        fr: 'Photo de profil',
        en: 'Profile picture',
    },
    step1: {
        fr: 'Langues parlées',
        en: 'Spoken languages',
    },
    step2: {
        fr: 'Cursus Universitaire',
        en: 'Academic background',
    },
    step3: {
        fr: 'Biographie',
        en: 'Biography',
    },
    nextButton: {
        fr: 'Suivant',
        en: 'Next',
    },
};
