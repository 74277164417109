/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import {
    Collapse, Card, Steps, Button, Typography,
} from 'antd';
import { loadProfileImage } from '../../api/images';
import { API_REQ_HEADER } from '../../config';
import ProfilePicture from '../../components/ProfilePicture';
import StudiesForm from './StudiesForm';
import BiographyForm from './BiographyForm';
import SpokenLanguages from './SpokenLanguages';
import { updateUserAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/GuidedFilling.texts';

const lang = getLang();
const { Panel } = Collapse;
const { Step } = Steps;
const { Title } = Typography;

const GuidedFilling = ({ user, updateUserAsync }) => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (activeStep === 0) {
            const pictureURL = loadProfileImage(user._id, 'preview');
            const verifyProfilePicture = async () => {
                try {
                    await axios.get(pictureURL, API_REQ_HEADER);
                    let newStep = 1;
                    const { studiesHistory, spokenLanguages } = user;
                    if (
                        spokenLanguages
                        && spokenLanguages.length
                        && studiesHistory
                        && studiesHistory.length
                    ) {
                        newStep = 3;
                    } else if (spokenLanguages && spokenLanguages.length) newStep = 2;
                    setActiveStep(newStep);
                } catch (err) {
                    // do nothing
                }
            };
            verifyProfilePicture();
        }
    }, [activeStep]);

    const showStep = () => {
        switch (activeStep) {
            case 0:
                return (
                    <ProfilePicture
                        onUpload={() => {
                            setActiveStep(1);
                            updateUserAsync({});
                        }}
                    />
                );
            case 1:
                return (
                    <div
                        style={{ margin: '30px auto', justifyContent: 'center' }}
                        className="flex"
                    >
                        <SpokenLanguages />
                    </div>
                );
            case 2:
                return <StudiesForm />;
            case 3:
                return <BiographyForm fromGuidedFilling />;
            default:
                return <div />;
        }
    };

    return (
        <Card id="unpadded-card" style={{ backgroundColor: '#FFF' }}>
            <Collapse bordered={false} defaultActiveKey={1}>
                <Panel
                    header={(
                        <Title level={4}>
                            {TEXTS.stepperTitle[lang]}
                            {activeStep + 1}
                            /4
                        </Title>
                    )}
                    key="1"
                    className="site-collapse-custom-panel"
                >
                    <div style={{ padding: 24 }}>
                        <Steps
                            style={{ marginBottom: 30 }}
                            size="small"
                            current={activeStep}
                        >
                            <Step title={TEXTS.step0[lang]} />
                            <Step title={TEXTS.step1[lang]} />
                            <Step title={TEXTS.step2[lang]} />
                            <Step title={TEXTS.step3[lang]} />
                        </Steps>
                        <div style={{ marginBottom: 15 }}>{showStep()}</div>
                        {activeStep === 1 || activeStep === 2 ? (
                            <Button
                                onClick={() => setActiveStep(activeStep + 1)}
                                className="center-button"
                                type="primary"
                            >
                                {TEXTS.nextButton[lang]}
                            </Button>
                        ) : null}
                    </div>
                </Panel>
            </Collapse>
        </Card>
    );
};

GuidedFilling.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(GuidedFilling);
