export default {
    emptyImages: {
        fr: "Ce patient n'a téléchargé aucune photo",
        en: "This patient dosen't have any uploaded picture",
    },
    header: {
        fr: 'Vos patients',
        en: 'Your patients',
    },
    headerToolip: {
        fr: 'Liste des patients vous autorisant a consulter leurs photos',
        en: 'Patients that allowed you to consult their picture',
    },
    uploadButton: {
        fr: 'Ajouter une photo',
        en: 'Upload a picture',
    },
    uploadSuccessMSG: {
        fr: 'Photo ajoutée avec succès.',
        en: 'Picture uploaded successfully.',
    },
    uploadErrorMSG: {
        fr: "Une erreur s'est produite.",
        en: 'An error has occured, please retry.',
    },
};
