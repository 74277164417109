import React from 'react';
import {
    AppstoreTwoTone,
    HeartTwoTone,
    ProfileTwoTone,
    SettingTwoTone,
    VideoCameraTwoTone,
    CarryOutTwoTone,
    HistoryOutlined,
    CameraOutlined,
    FolderOpenOutlined,
} from '@ant-design/icons';

const twoToneColor = '#fcb714';

const config = {
    dashboard: {
        name: 'dashboard',
        title: 'Tableau de bord',
        icon: style => (
            <AppstoreTwoTone twoToneColor={twoToneColor} style={style} />
        ),
        route: '/dashboard',
    },
    profile: {
        name: 'profile',
        title: 'Mon profil',
        icon: style => (
            <ProfileTwoTone twoToneColor={twoToneColor} style={style} />
        ),
        route: '/profile',
    },
    agenda: {
        name: 'agenda',
        title: 'Agenda',
        icon: style => <HeartTwoTone twoToneColor={twoToneColor} style={style} />,
        route: '/agenda',
    },
    images: {
        name: 'images',
        title: 'Photos patients',
        icon: style => (
            <CameraOutlined style={{ ...style, color: twoToneColor }} />
        ),
        route: '/images',
    },
    documents: {
        name: 'documents',
        title: 'Documents patients',
        icon: style => (
            <FolderOpenOutlined style={{ ...style, color: twoToneColor }} />
        ),
        route: '/documents',
    },
    config: {
        name: 'config',
        title: 'Configuration initiale',
        icon: style => (
            <SettingTwoTone twoToneColor={twoToneColor} style={style} />
        ),
        route: 'config',
    },
    motif: {
        name: 'motif',
        title: 'Motif de consultation',
        icon: style => (
            <SettingTwoTone twoToneColor={twoToneColor} style={style} />
        ),
        route: 'motif',
    },
};

export const subMenuConfig = {
    title: 'Téléconsultations',
    icon: style => (
        <VideoCameraTwoTone twoToneColor={twoToneColor} style={style} />
    ),
    key: 't',
    items: {
        't-incoming': {
            name: 't-incoming',
            title: 'Programmées',
            route: '/t-incoming',
            icon: style => (
                <CarryOutTwoTone twoToneColor={twoToneColor} style={style} />
            ),
        },
        't-history': {
            name: 't-history',
            title: 'Historique',
            route: '/t-history',
            icon: style => (
                <HistoryOutlined style={{ ...style, color: twoToneColor }} />
            ),
        },
    },
};

export const mapRouteToElement = {
    '/dashboard': config.dashboard,
    '/agenda': config.agenda,
    '/images': config.images,
    '/documents': config.documents,
    '/profile': config.profile,
    '/config': config.config,
    '/t-incoming': subMenuConfig.items['t-incoming'],
    '/t-history': subMenuConfig.items['t-history'],
    '/motif': config.motif,
};

export default config;
