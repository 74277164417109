export default {
    breadCrumb1: {
        fr: 'Téléconsultations',
        en: 'Teleconsultations',
    },
    breadCrumb2: {
        fr: 'Historique',
        en: 'History',
    },
    count: {
        fr: 'Téléconsultations effectuées',
        en: 'Accomplished teleconsultations',
    },
    title: {
        fr: 'Historique des téléconsultations',
        en: 'Teleconsultations history',
    },
    duration: {
        fr: 'Durée (minutes)',
        en: 'Duration (minutes)',
    },
    cPurpose: {
        fr: 'Motif de consultation',
        en: 'Reason for consultation',
    },
    birthDate: {
        fr: 'Date de naissance',
        en: 'Birthdate',
    },
    securityNumber: {
        fr: 'Numéro de securité sociale',
        en: 'Security number',
    },
    emptyList: {
        fr: 'Vous n’avez effectué aucune téléconsultation.',
        en: "You don't have any accomplished teleconsultation.",
    },
};
