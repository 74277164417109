export default {
    cardTitle: {
        fr: 'Mes prochaines téléconsultations',
        en: 'Upcoming teleconsultations',
    },

    cardEmpty: {
        fr: 'Aucune téléconsultation à venir.',
        en: 'No upcoming teleconsultations.',
    },
};
