/* eslint-disable max-len */
import React from 'react';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { updateUserAsync } from '../../redux/actions/auth';
import languages from '../../helpers/languagesList';
import { getLang } from '../../config/helpers';

const lang = getLang();

const SpokenLanguages = ({ user, updateUserAsync }) => {
    const setSpokenLanguages = (nextTargetKeys) => {
        updateUserAsync({ spokenLanguages: nextTargetKeys });
        // message.success(TEXTS.updateSuccessMsg[lang]);
    };

    return (
        <div>
            <Checkbox.Group className="flex-column" options={languages[lang]} defaultValue={user ? user.spokenLanguages : []} onChange={setSpokenLanguages} />

        </div>
    );
};

SpokenLanguages.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});
const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(SpokenLanguages);
