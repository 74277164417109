import React from 'react';
import PropTypes from 'prop-types';


const PlayStore = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/google-play-store.svg"
        alt="Playstore-logo"
    />
);


PlayStore.propTypes = {
    style: PropTypes.object,
};

PlayStore.defaultProps = {
    style: {},
};


export default PlayStore;
