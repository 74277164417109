/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { notification, message } from 'antd';
import { getLang } from '../../../config/helpers';
import { auth } from '../../../firebase';
import * as AuthAPI from '../../../api/auth';
import { addImages } from '../../../api/images';
import { setAppIsLoading, setContentIsLoading } from '../loading';
import TEXTS from './_texts/index.texts';
import { refreshSubscription } from '../../../api/stripeSubscription';

export const SET_USER = 'SET_USER';
export const SET_PRICING = 'PRICING';


export const setUser = value => ({
    type: SET_USER,
    payload: value,
});


export const SET_PATIENTS = 'SET_PATIENTS';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';

export const setPatients = value => ({
    type: SET_PATIENTS,
    payload: value,
});

export const updatePatient = value => ({
    type: UPDATE_PATIENT,
    payload: value,
});

const lang = getLang();

export const sessionExpired = async (err, dispatch) => {
    const errMsg = TEXTS[err.code];

    if (errMsg) {
        notification.error({
            message: TEXTS.errorNotificationMessage[lang],
            description: errMsg[lang],
        });
        await auth.signOut();
        await AuthAPI.logout();
        dispatch(setUser(null));
    } else if (err.response && err.response.data) {
        if (err.response.data.code === 'auth/argument-error') {
            await auth.signOut();
            await AuthAPI.logout();
            dispatch(setUser(null));
        }
    }
    return false;
};


const tooManyRequestsFbError = (err) => {
    if (err.response) {
        if (err.response.data) {
            if (err.response.data.code === 'auth/internal-error') {
                notification.error({
                    message: TEXTS.errorNotificationMessage[lang],
                    description: TEXTS['auth/too-many-requests'][lang],
                });
            }
        }
    }
};

export const checkAuthAsync = fromUnconfirmed => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const authStatus = await AuthAPI.getStatus();
        if (authStatus.user) dispatch(setUser(authStatus.user));
        if (fromUnconfirmed) {
            if (!authStatus.user.email_verified) message.warning(TEXTS.emailStillUnconfirmed[lang]);
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
};


export const recoverPassword = async (email) => {
    try {
        await AuthAPI.resetPasswordDoctor(email);
        notification.success({
            message: TEXTS.notifEmailVerificationSuccessMessage[lang],
            description: TEXTS.notifPasswordResetSuccessDescription[lang],
        });
    } catch (err) {
        tooManyRequestsFbError(err);
        sessionExpired(err);
    }
};


export const setPatientsAsync = () => async (dispatch) => {
    try {
        const res = await AuthAPI.getPatients();
        dispatch(setPatients(res.data));
    } catch (err) {
        tooManyRequestsFbError(err);
        sessionExpired(err, dispatch);
    }
};

export const sendEmailVerification = async () => {
    try {
        await AuthAPI.generatEmailVerification();
        notification.success({
            message: TEXTS.notifEmailVerificationSuccessMessage[lang],
            description: TEXTS.notifEmailVerificationSuccessDescription[lang],
        });
    } catch (err) {
        tooManyRequestsFbError(err);
        sessionExpired(err);
    }
};
export const updateUserEmail = email => async (dispatch) => {
    try {
        const res = await AuthAPI.updateMail(email);
        dispatch(setUser(res.data));
        message.success(TEXTS.updateEmailSuccess[lang]);
    } catch (err) {
        tooManyRequestsFbError(err);
        sessionExpired(err, dispatch);
    }
};

export const signUpAsync = credentials => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const res = await auth.createUserWithEmailAndPassword(
            credentials.email,
            credentials.password,
        );
        if (res.user) {
            const token = await res.user.getIdToken();
            // res.user.sendEmailVerification();
            credentials._id = res.user.uid;
            credentials.token = token;
            delete credentials.password;
            const response = await AuthAPI.register(credentials);

            if (response) {
                await addImages(credentials._id, 'com', credentials.proCard);

                dispatch(setUser(response.data));
            }
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
};

export const loginAsync = credentials => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        try {
            const res = await auth.signInWithEmailAndPassword(
                credentials.email,
                credentials.password,
            );
            if (res.user) {
                const idToken = await res.user.getIdToken(true);
                const id = res.user.uid;
                const response = await AuthAPI.login({ idToken, id });
                const AuthenticatedUser = response.data;
                localStorage.setItem('disconnected', false);
                dispatch(setUser(AuthenticatedUser));
                await refreshSubscription();
            }
        } catch (err) {
            sessionExpired(err, dispatch);
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
    return null;
};

export const disconnectAsync = () => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        await auth.signOut();
        await AuthAPI.logout();
        dispatch(setUser(null));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
};


export const updateUserAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AuthAPI.update(body);
        if (res.data) {
            dispatch(setUser(res.data));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export const setPricingDoctor = data => ({
    type: SET_PRICING,
    payload: data,
});

export const updateDoctorPricingAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AuthAPI.updatePricing(body);
        if (res.user) {
            dispatch(setPricingDoctor(res.user));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
