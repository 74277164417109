import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
// import { Button } from 'antd';
import CalendarHeader from './CalendarHeader';
import CalendarBody from './CalendarBody';
import './styles.css';
// import { getLang } from '../../config/helpers';
// import TEXTS from './_texts/index.texts';

// const lang = getLang();

export default class WeekCalendar extends Component {
    state = {
        week: 0,
        dates: [],
        weekDays: [
            { events: [] },
            { events: [] },
            { events: [] },
            { events: [] },
            { events: [] },
            { events: [] },
            { events: [] },
        ],
    }

    componentDidMount() {
        const { events } = this.props;
        this.setState({ events }, () => this.daysHandler(0));
    }

    componentWillReceiveProps(newProps) {
        const { events } = this.props;
        return newProps.events !== events
            && this.setState({ events: newProps.events }, () => this.daysHandler(0));
    }

    daysHandler = (sum) => {
        const { events, weekDays, week } = this.state;
        const { dateLabel } = this.props;

        const weekNumber = week + sum;

        const startOfWeek = moment()
            .add(weekNumber, 'days')
            .startOf('days');
        const endOfWeek = moment()
            .add(weekNumber === 0 ? weekDays.length - 1 : weekNumber + weekDays.length - 1, 'days')
            .endOf('days');

        const days = [];
        let day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day.toDate());
            day = day.clone().add(1, 'd');
        }
        const d = [...weekDays];
        days.map((day, i) => {
            const evts = events.filter(
                e => moment(day).format('L') === moment(e[dateLabel]).format('L'),
            );
            d[i] = { events: evts, name: d[i].name };
            return d[i];
        });
        this.setState({
            week: weekNumber,
            dates: days,
            weekDays: d,
        });
    }

    render() {
        const { dates, weekDays, expandCalendar } = this.state;
        const { handleClickEvent } = this.props;
        return (
            <div className="calendar-container" id="react-week-calendar">

                <CalendarHeader dates={dates} daysHandler={this.daysHandler} />
                <CalendarBody
                    expandCalendar={expandCalendar}
                    dates={dates}
                    weekDays={weekDays}
                    handleClickEvent={handleClickEvent}
                />
            </div>
        );
    }
}


WeekCalendar.defaultProps = {
    dateLabel: 'date',
};

WeekCalendar.propTypes = {
    dateLabel: PropTypes.string,
    handleClickEvent: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
};
