/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';

const { RangePicker } = TimePicker;

const MyPicker = (props) => {
    const onBlur = () => {
        const inputs = [
            ...document
                .getElementById(`custom-range-picker-${props.name}`)
                .getElementsByTagName('input'),
        ];
        const startVal = inputs[0].value;
        const endVal = inputs[1].value;
        const start = moment(inputs[0].value, props.format);
        const end = moment(inputs[1].value, props.format);
        if (
            start
      && end
      && start.isValid()
      && end.isValid()
      && props.onChange
      && start.toString() < end.toString()
        ) {
            const res = [];
            res.push(startVal);
            res.push(endVal);
            props.onChange([start, end], res);
        } else {
            props.onError();
        }
    };

    return (
        <div id={`custom-range-picker-${props.name}`}>
            <RangePicker {...props} onBlur={onBlur} style={{ minWidth: 266 }} />
        </div>
    );
};

MyPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    format: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    name: PropTypes.string,
};

MyPicker.defaultProps = {
    name: Math.random().toString(),
};

export default MyPicker;
