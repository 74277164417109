import {
    Form, Input, Modal,
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';


const AddPurpose = (props) => {
    const { visible, onCancel, onCreate } = props;
    const [form] = Form.useForm();
    return (
        <Modal
            title="Ajout d'un motif de consultation"
            visible={visible}
            okText="Ajouter"
            cancelText="Annuler"
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch(() => {
                        // console.log('Validate Failed:', info);
                    });
            }}
            onCancel={onCancel}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item label="Motif de consultation" name="label" rules={[{ required: true }]}>
                    <Input style={{ width: '250px' }} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

AddPurpose.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default AddPurpose;
