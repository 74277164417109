/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Switch, Tooltip, Button, Modal, message,
} from 'antd';
import { connect } from 'react-redux';
import {
    VideoCameraOutlined,
    AudioOutlined,
    PoweroffOutlined,
} from '@ant-design/icons';
import { addImages } from '../../api/images';
import { checkPermission } from '../../api/auth';
import './styles.css';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/mediaControls.texts';

const lang = getLang();

const ImagePreview = ({
    b64Img, open, addImage, onCancel,
}) => (
    <Modal
        visible={open}
        onOk={addImage}
        onCancel={onCancel}
        width="min-content"
        okText={TEXTS.uploadOkText[lang]}
        okType="primary"
        cancelButtonProps={{ danger: true }}
        cancelText={TEXTS.uploadCancelText[lang]}
    >
        <img src={b64Img} alt="patient capture" />
    </Modal>
);

ImagePreview.propTypes = {
    b64Img: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    addImage: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

const MediaControls = ({
    handleCheckBox, canHangup, onHangup, activeTelec,
    canTakePicture, hasWebcam,
}) => {
    const [disableCam, setDisableCam] = useState(!hasWebcam);
    const [disableMic, setDisableMic] = useState(false);
    const [imagePrev, setImagePrev] = useState(null);
    const [hasPermission, setHasPermission] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const res = await checkPermission(activeTelec.patient._id);
                if (res.data) setHasPermission(true);
            } catch (err) {
                // nada
            }
        })();
    }, [activeTelec]);

    const onChange = (item) => {
        handleCheckBox(item);
        if (item === 'cam') {
            setDisableCam(!disableCam);
        } else {
            setDisableMic(!disableMic);
        }
    };

    const resetCanvas = () => {
        setImagePrev(null);
        const canvas = document.getElementById('canvas');
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    const onSaveImage = () => {
        const canvas = document.getElementById('canvas');

        canvas.toBlob(async (blob) => {
            try {
                await addImages(activeTelec.patient._id, 'photos', blob);
                message.success(TEXTS.uploadImageSuccess[lang]);
            } catch (error) {
                message.error(TEXTS.uploadImageError[lang]);
            }
            resetCanvas();
        });
    };


    const takePicture = () => {
        const video = document.getElementById('remote-video');
        const canvas = document.getElementById('canvas');

        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        const b64Img = canvas.toDataURL('image/png');
        setImagePrev(b64Img);
    };

    return (
        <div className="flex">
            <div className="vid-preview-item">
                <VideoCameraOutlined style={{ marginRight: 8, fontSize: 18 }} />
                <Switch
                    onChange={() => onChange('cam')}
                    checked={!disableCam}
                    defaultChecked
                />
            </div>

            <div className="vid-preview-item">
                <AudioOutlined style={{ marginRight: 8, fontSize: 18 }} />
                <Switch
                    onChange={() => onChange('mic')}
                    defaultChecked
                    checked={!disableMic}
                />
            </div>

            {canTakePicture && hasPermission ? (
                <Button
                    style={{ marginLeft: 8 }}
                    type="primary"
                    ghost
                    onClick={takePicture}
                >
                    {TEXTS.takePictureButton[lang]}
                </Button>
            ) : null}
            {canHangup ? (
                <div className="vid-preview-item" style={{ cursor: 'pointer' }}>
                    <Tooltip title={TEXTS.hangupButton[lang]}>
                        <PoweroffOutlined
                            style={{ color: '#ff4d4f', fontSize: 20, marginTop: 2 }}
                            onClick={onHangup}
                        />
                    </Tooltip>


                </div>
            ) : null}
            <ImagePreview
                open={imagePrev !== null}
                b64Img={imagePrev}
                addImage={onSaveImage}
                onCancel={resetCanvas}
            />
        </div>
    );
};

MediaControls.propTypes = {
    handleCheckBox: PropTypes.func.isRequired,
    canHangup: PropTypes.bool.isRequired,
    onHangup: PropTypes.func,
    activeTelec: PropTypes.object.isRequired,
    canTakePicture: PropTypes.bool.isRequired,
    hasWebcam: PropTypes.bool,
};

MediaControls.defaultProps = {
    onHangup: () => {},
    hasWebcam: true,
};


const stateToProps = state => ({
    user: state.auth.user,
    activeTelec: state.consultations.activeTelec,
});

export default connect(stateToProps)(MediaControls);
