/* eslint-disable react/destructuring-assignment */
import {
    Table, Card, Button, message, Popconfirm,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import { addCPurposeAsync, setCPurposesListAsync, removeCPurposeAsync } from '../../redux/actions/purpose';
import AddPurpose from './add/addPurpose';

const lang = getLang();


const CPurpose = (props) => {
    const [displayModal, setDisplayModal] = useState(false);
    const { removeCPurposeAsync } = props;


    useEffect(() => {
        const { setCPurposesListAsync } = props;
        setCPurposesListAsync();
    }, []);


    const confirm = (_id) => {
        removeCPurposeAsync(_id);
    };

    const cancel = () => {
        message.error('Vous avez annuler la suppression');
    };

    const columns = [
        {
            title: 'Motif',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: '_id',
            render: _id => (
                <Popconfirm
                    title="Etes vous sur de supprimer ce motif ?"
                    onConfirm={() => confirm(_id)}
                    onCancel={cancel}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Button type="danger" size="small" style={{ marginTop: 10 }}>
                        {' '}
                        <DeleteOutlined style={{ marginRight: 5 }} />
                        {' '}
                        Supprimer
                        {' '}
                    </Button>
                </Popconfirm>
            ),
        },
    ];


    const onCreate = (values) => {
        const { label } = values;
        const { _id } = props.user;
        const { addCPurposeAsync } = props;
        addCPurposeAsync({ label, doctor: _id });
        setDisplayModal(false);
        message.success(TEXTS.singleAddSuccess[lang]);
    };


    return (
        <div className="section-content-container">
            <Card title={TEXTS.title[lang]}>
                <Button type="primary" size="middle" style={{ marginTop: -'20px', marginBottom: '20px' }} onClick={() => setDisplayModal(true)}>
                    Ajouter un motif de Consultation
                </Button>
                <AddPurpose
                    visible={displayModal}
                    onCreate={onCreate}
                    onCancel={() => {
                        setDisplayModal(false);
                    }}
                />
                <Table
                    columns={columns}
                    dataSource={props.purposesListStore}
                    locale={{ emptyText: TEXTS.emptyList[lang] }}
                    rowKey="_id"
                    pagination={{ pageSize: 7 }}
                />
            </Card>
        </div>
    );
};

CPurpose.propTypes = {
    removeCPurposeAsync: PropTypes.func.isRequired,
    purposesListStore: PropTypes.array.isRequired,
    setCPurposesListAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    addCPurposeAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    purposesListStore: state.purposes.cPurposesList,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        addCPurposeAsync,
        setCPurposesListAsync,
        removeCPurposeAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(CPurpose);
