/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, Button, Spin } from 'antd';
import TelecCard from '../../components/TelecCard';
import TelecModal from '../../components/TelecModal';
import { setIncomingListAsync } from '../../redux/actions/consultations';
import { navigateTo } from '../../history';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/incomingTelecList.texts';
import './styles.css';

const lang = getLang();

const TelecList = ({
    incomingList,
    setIncomingListAsync,
    user,
    contentIsLoading,
}) => {
    useEffect(() => {
        setIncomingListAsync();
    }, [user]);

    const [selectedTelec, selectTelec] = useState(null);

    return (
        <Card
            id="unpadded-card"
            title={(
                <Button
                    type="link"
                    className="link-button-title"
                    onClick={() => {
                        navigateTo('/t-incoming');
                    }}
                >
                    <b>{TEXTS.cardTitle[lang]}</b>
                </Button>
            )}
            className="dashboard-incoming-telec-container"
        >
            <Spin spinning={contentIsLoading}>
                <div className="flex flex-wrap">
                    {incomingList.length ? (
                        incomingList.map(telec => (
                            <TelecCard
                                key={telec._id}
                                onClick={() => { selectTelec(telec); }}
                                telec={telec}
                                showActions={false} />

                        ))
                    ) : (
                        <div style={{ margin: '20px auto' }}>{TEXTS.cardEmpty[lang]}</div>
                    )}
                </div>
                {selectedTelec ? (
                    <TelecModal
                        telec={selectedTelec}
                        open
                        handleClose={() => {
                            selectTelec(null);
                        }}
                    />
                ) : null}
            </Spin>
        </Card>
    );
};

TelecList.propTypes = {
    incomingList: PropTypes.array.isRequired,
    setIncomingListAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
};

const stateToProps = state => ({
    incomingList: state.consultations.incomingList,
    contentIsLoading: state.loading.contentIsLoading,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setIncomingListAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(TelecList);
